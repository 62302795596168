import { useContext, useEffect } from "react";
import "./footer.scss";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../modals/alert-modal/alert-modal";
import facebookIcon from "./../../assets/icons/facebook.svg";
import instagramIcon from "./../../assets/icons/instagram.svg";
import linkedinIcon from "./../../assets/icons/linkedin.svg";
import tikTokIcon from "./../../assets/icons/tiktok.svg";
import youtubeIcon from "./../../assets/icons/youtube.svg";
import eduardDigitalLogo from "./../../assets/logos/eduard-digital.png";
import { contactPhoneNumbers } from "../../utils/constants";

function Footer() {
  const { handleModal } = useContext(ModalContext);

  const openContactModal = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={reject}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  return (
    <div className="footer-container pb-5 pt-5">
      <div className="container">
        <div className="pl-2 pr-2 pl-md-0 pr-md-0">
          <div className="h1 text-white">Show, Motivație, Inspirație</div>

          <div className="d-flex gap-3 flex-wrap mt-4 justify-content-between">
            <div className="mt-2 d-flex flex-direction-column justify-content-center">
              <a className="no-decoration" href="/">
                <p>Acasă</p>
              </a>
              <a className="no-decoration" href="/ce-spun-speakerii">
                <p>Speakeri</p>
              </a>
              <a className="no-decoration" href="/#bilete">
                <p>Bilete</p>
              </a>
              {/* <HashLink className="no-decoration" to="/#bilete"><p>Bilete</p></HashLink> */}

              <a className="no-decoration" href="/devino-speaker">
                <p>Devino speaker</p>
              </a>
              <div onClick={openContactModal} className="a-styling no-decoration" href="">
                <p>Contact</p>
              </div>
            </div>

            <div className="mt-2 d-flex flex-direction-column justify-content-center">
              <a className="no-decoration" href="/termeni-si-conditii">
                <p>Termeni și condiții</p>
              </a>
              <a className="no-decoration" href="/politica-de-confidentialitate">
                <p>Politica de confidențialitate</p>
              </a>
              <a className="no-decoration" href="/politica-de-cookies">
                <p>Politica de cookies</p>
              </a>
              <a className="no-decoration" href="/acord-newsletter">
                <p>Acord newsletter</p>
              </a>
              <div onClick={openContactModal} className="a-styling no-decoration" href="">
                <p>Propune un speaker</p>
              </div>
            </div>

            <div className="d-flex flex-direction-column justify-content-center">
              <div className="d-flex gap-2 justify-content-between">
                <a className="no-decoration" href=" https://www.facebook.com/profile.php?id=61551091742308" target="_blank" rel="noopener noreferrer">
                  <img src={facebookIcon} alt="Facebook"></img>
                </a>
                <a className="no-decoration" href="https://www.youtube.com/@findyourwhy.ro./featured" target="_blank" rel="noopener noreferrer">
                  <img src={youtubeIcon} alt="YouTube"></img>
                </a>
                <a className="no-decoration" href="https://www.instagram.com/findyourwhy.ro" target="_blank" rel="noopener noreferrer">
                  <img src={instagramIcon} alt="Instagram"></img>
                </a>
                <a className="no-decoration" href="https://www.tiktok.com/@findyourwhy.ro?_t=8i2zU2T6f8j&_r=1" target="_blank" rel="noopener noreferrer">
                  <img src={tikTokIcon} alt="TikTok"></img>
                </a>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-2">
                Website built by
                <a className="no-decoration" href="https://eduard.digital" target="_blank" rel="noopener noreferrer">
                  <img src={eduardDigitalLogo} alt="Eduard.digital"></img>
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex flex-direction-column justify-content-center align-items-center mt-3">
            Copyright @ 2023 Find Your Why by Flony Motivational. All Rights Reserved
          </div>
          <div className="d-flex flex-direction-column justify-content-center align-items-center mt-3">
            {`Contact: ${contactPhoneNumbers[0]} sau ${contactPhoneNumbers[1]}`}
          </div>
        </div>

        <div className="footer-logo">
          <img width="100%" src={require("./../../assets/images/footer-logo.png")} alt="Find your why"></img>
        </div>
      </div>
    </div>
  );
}

export default Footer;
