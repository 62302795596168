import { useEffect, useState } from "react";
import "./sales-timer.scss";
import ButtonHero from "../button-hero/button-hero";
import { activePriceTimeline, promotionNames, salesDeadline } from "../../utils/constants";

function SalesTimer() {
  const [count, setCount] = useState((salesDeadline.getTime() - new Date().getTime()) / 1000);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    // console.log("count", count);
    if (count !== -1) {
      // -1 is the default value
      if (count > 0) {
        // Only start the countdown if the count is greater than 0
        const timerId = setInterval(() => {
          setCount((prevCount) => prevCount - 1);
        }, 1000);

        // Clear the interval when the component is unmounted or when the countdown reaches 0
        return () => clearInterval(timerId);
      } else {
        console.log("countdown ended");
      }
    }
  }, [count]);

  const closeBanner = () => {
    setIsBannerVisible(false);
  };

  return (
    <div className="sales-timer-container">
      {isBannerVisible && count > 0 ? (
        <>
          <div className="sales-timer d-flex justify-content-around align-items-center">
            <div className="ml-1 text-center">
              <div className="h5 font-black">{`${String(Math.floor(count / (3600 * 24))).padStart(2, "0")}`}</div>
              <div className="p2 font-normal">zile</div>
            </div>

            <div className="ml-1 text-center">
              <div className="h5 font-black">{`${String(Math.floor((count % (3600 * 24)) / 3600)).padStart(
                2,
                "0"
              )}`}</div>
              <div className="p2 font-normal">ore</div>
            </div>

            <div className="ml-1 text-center">
              <div className="h5 font-black">{`${String(Math.floor((count % 3600) / 60)).padStart(2, "0")}`}</div>
              <div className="p2 font-normal">minute</div>
            </div>

            <div className="ml-1 text-center">
              <div className="h5 font-black">{`${String(Math.floor(count % 60)).padStart(2, "0")}`}</div>
              <div className="p2 font-normal">secunde</div>
            </div>
            {/* <div className="p2 d-flex flex-wrap gap-1 text-center justify-content-center">
              <div className="d-block d-lg-none">
                <div className="text-nowrap">{`${promotionNames[activePriceTimeline]}`}</div>
                <div className="text-nowrap">{`Cel mai bun preț`}</div>
              </div>
              <div className="d-none d-lg-block">
                <div className="text-nowrap">{`${promotionNames[activePriceTimeline]}`}</div>
                <div className="text-nowrap">{`Cel mai bun preț`}</div>
              </div>
            </div> */}

            {/* 
            <div className="p2 d-flex flex-wrap gap-1 text-center justify-content-center">
              <div className="d-block d-lg-none">
                <div className="text-nowrap">{`Extra 30% REDUCERE:`}</div>
                <div className="text-nowrap">{`introdu codul `}<span className="text-black font-bold">CRACIUN30</span>{` pe ia bilet`}</div>
                <div className="text-nowrap">{`(inainte de selectarea locurilor)`}</div>
              </div>
              <div className="d-none d-lg-block">
                <div className="text-nowrap">{`Extra 30% REDUCERE: introdu codul `}<span className="text-black font-bold">CRACIUN30</span></div>
                <div className="text-nowrap">{`pe ia bilet (inainte de selectarea locurilor) `}</div>
              </div>
            </div> */}

            {/* <div className="p2 d-flex flex-wrap gap-1 text-center justify-content-center">
              <div className="d-block d-lg-none">
                <div className="text-nowrap">
                  {`Folosește codul `}
                  <span className="text-black font-bold">SECRET30</span>
                  {` și ai: `}
                </div>
                <div className="text-nowrap">{`Early bird: (1 +1 GRATIS la Standard si VIP)`}</div>
                <div className="text-nowrap">{`si 30% REDUCERE la Diamond si Supreme`}</div>
              </div>

              <div className="d-none d-lg-block">
                <div className="text-nowrap"> {`Early bird: (1 +1 GRATIS la Standard si VIP) și`}</div>
                <div className="text-nowrap">
                  {`30% REDUCERE la Diamond si Supreme cu codul `}
                  <span className="text-black font-bold">SECRET30</span>
                </div>
              </div>
            </div> */}

            <div className="p2 d-flex flex-wrap gap-1 text-center justify-content-center">
              <div className="d-block d-lg-none">
                <div className="text-nowrap">
                  <span className="font-bold">{`Early bird: `}</span>
                  {`Cel mai bun preț și Extra: `}
                </div>
                <div className="text-nowrap">
                  {`30% REDUCERE folosind codul `}
                </div>
                <div className="text-nowrap">
                  <span className="text-black font-bold">SECRET30</span>
                  {`pe iabilet.ro `}
                </div>
              </div>

              <div className="d-none d-lg-block">
                <div className="text-nowrap">
                  <span className="font-bold">{`Early bird: `}</span>
                  {`Cel mai bun preț și Extra 30% REDUCERE `}
                </div>
                <div className="text-nowrap">
                  {` folosind codul `}
                  <span className="text-black font-bold">SECRET30</span>
                  {` pe iabilet.ro `}
                </div>
              </div>
            </div>

            <ButtonHero color="black-white" is3d={false} link={"/#bilete"} content="Ia bilet" size="thin"></ButtonHero>

            <div onClick={() => closeBanner()} className="close-banner close-icon" tabIndex={0}></div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
export default SalesTimer;
