import ButtonHero from "../button-hero/button-hero";
import "./hero.scss";

function Hero() {
  return (
    <div className="hero-container">
      <div className="hero-background">
        <img alt="motivational-event" className="w-100" src={require("./../../assets/images/hero-3.webp")}></img>
      </div>
      <header className="h-100 container d-flex justify-content-evenly align-items-center flex-direction-column">
        <div className="d-flex justify-content-evenly align-items-center flex-direction-column animate__animated animate__fadeIn">
          <h1 className="hero-heading mt-4 mt-md-0">
            <p className="hero-text-underline underline-variant-hero mb-0">
              Participă la cel mai Tare <wbr></wbr> Show Motivațional din România
            </p>
            {/* <p className='hero-text-underline underline-variant-2 mt-1 mb-0'>
            </p> */}
          </h1>
          <p className="p1 h4-md mt-2 font-bold text-center hero-text-underline underline-variant-3">
            Descoperă 'de ce-ul' tău și declanșează o schimbare profundă în viața ta.
            <br />
            Te distrezi, pleci motivat și inspirat!
            {/* Pleci acasă cu motivația să îți transformi venitul, cariera și întreaga viață */}
          </p>
        </div>
        <div className="w-100 pl-1 mt-4 p-relative d-flex justify-content-center align-items-center flex-direction-column animate__animated animate__fadeIn">
          <ButtonHero
            content="Rezervă-ți locul"
            is3d={true}
            isPulsing={true}
            width="500px"
            link="/#bilete"></ButtonHero>
          {/* <p className="mt-1 p1 font-semi-bold h5-md font-black-md text-white mt-2">*Prinde oferta 1+1 gratis</p> */}
        </div>
        <div className="mt-4 h4 hero-text-underline underline-variant-4 animate__animated animate__fadeIn">
          <div>Anunțăm noua dată în curând</div>
          <div>București - Sala Palatului</div>
        </div>
      </header>
    </div>
  );
}

export default Hero;
