import { useEffect, useState, useCallback } from "react";
import "./group-offer-form.scss";
import { FieldValues, useForm } from "react-hook-form";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";

function GroupOfferForm({ onSubmit, onDismiss, isValidated = () => {} }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
    reset,
    setValue,
    setError,
  } = useForm({ mode: "onChange" });
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [numberOfPeopleInput, setNumberOfPeopleInput] = useState("");
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  useEffect(() => {
    console.log("errors", errors);
    if (emailInput && phoneNumber) {
      isValidated(true);
      setIsFormValid(true);
    } else {
      isValidated(false);
      setIsFormValid(false);
    }
  }, [emailInput, phoneNumber, numberOfPeopleInput, isValidated, errors]);

  const submit = async (args) => {
    try {
      setIsSendingMessage(true);
      console.log("Submited", args);
      const functions = getFunctions();
      console.log("functions", functions);
      const message = {
        name: args?.nameInput,
        email: args?.emailInput,
        phone: args?.phoneNumber,
        numberOfPeople: args?.numberOfPeopleInput,
      };
      await httpsCallable(functions, "sendGroupOfferForm")(message);
      onSubmit(args);
      toast(`Mesaj trimis cu success !`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "dark",
      });
    } catch (err) {
      console.error(err);
      toast("Nu am putut trimite mesajul !", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
        theme: "dark",
      });
    } finally {
      setIsSendingMessage(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="">
        <div className="w-100 d-flex gap-1 flex-direction-column">
          <div>Folosește formularul de mai jos sau trimite-ne un e-mail la adresa contact@findyourwhy.ro</div>
          <div className="text-left mt-2">
            <label className="" htmlFor="nameInput">
              <div className={`${errors?.nameInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Nume`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="nameInput"
                value={nameInput}
                {...register("nameInput", { validate: true })}
                onChange={(e) => {
                  setNameInput(e.target.value);
                  setValue("nameInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left mt-2">
            <label className="" htmlFor="emailInput">
              <div className={`${errors?.emailInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`E-mail`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="emailInput"
                value={emailInput}
                {...register("emailInput", { validate: true })}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                  setValue("emailInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left mt-2">
            <label className="" htmlFor="phoneNumber">
              <div className={`${errors?.phoneNumber ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Telefon`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="phoneNumber"
                value={phoneNumber}
                {...register("phoneNumber", { validate: true })}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setValue("phoneNumber", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-left mt-2">
            <label className="" htmlFor="numberOfPeopleInput">
              <div className={`${errors?.numberOfPeopleInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Numărul de persoane (minim 7)`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="numberOfPeopleInput"
                type="number"
                value={numberOfPeopleInput}
                {...register("numberOfPeopleInput", { validate: (v)=>v>=7 || "Atenție: Oferta e valabilă doar pentru minim 7 persoane !"})}
                onChange={(e) => {
                  setNumberOfPeopleInput(e.target.value);
                  setValue("numberOfPeopleInput", e.target.value, { shouldValidate: true });
                }}></input>
            </label>
          </div>

          <div className="text-sm mt-2">
            {`* Apăsând pe butonul "Trimite" îți dai consimțământul că esți de acord cu `}{" "}
            <a target="_blank" rel="noopener noreferrer" href="/politica-de-confidentialitate">
              Politica de Confidențialitate
            </a>
          </div>
        </div>
        <div className={`input-error text-center font-black text-underline text-underline-black underline-variant-9 ${Object.keys(errors).length ? "mt-3" : "invisible"}`}>{errors?.[Object.keys(errors)?.[0]]?.message || "Completează toate câmpurile !"}</div>

        <div className="modal-action-area text-center d-flex mt-2 justify-content-around flex-wrap gap-2">
          <div
            className={"button cancel-button flex-1 d-flex justify-content-center"}
            onClick={() => {
              onDismiss();
            }}>
            {"Anulează"}
          </div>
          <div
            className={`p-relative button confirm-button flex-1 d-flex justify-content-center ${
              isFormValid ? "" : "disabled"
            } ${isSendingMessage ? "disabled" : ""}`}>
            {isSendingMessage ? "Se trimite..." : "Trimite"}

            {!isSendingMessage ? (
              <input className="ghost-over cursor-pointer" type="submit" name="submitButton"></input>
            ) : (
              <></>
            )}
          </div>
        </div>
      </form>
    </>
  );
}
export default GroupOfferForm;
