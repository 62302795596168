import "./become-speaker.scss";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import Footer from "../../components/footer/footer";
import {useEffect, useState} from "react";
import {allSpeakers} from "../../utils/constants";
import {useParams} from "react-router-dom";
import SpeakerThumbnail from "../../components/speaker-thumbnail/speaker-thumbnail";
import ButtonHero from "../../components/button-hero/button-hero";
import microphoneImage from "./../../assets/icons/microphone.png"

function BecomeSpeaker() {
  const [currentSpeaker, setCurrentSpeaker] = useState(null);

  return (
    <div className="">
      <CustomCursor />

        <>
          <div className="p-relative">
            <div className="d-md-flex justify-content-start container">
              <div className="left-big-shape-container d-flex h-100vh flex-direction-column justify-content-center text-white h2 h0-lg text-center text-md-left pl-0 pl-md-0">
                <div className="left-big-shape"></div>
                <div className="title-image-container">
                  <img src={microphoneImage} alt={`Devino speaker`}></img>
                </div>
              </div>

              <div className="ml-2 ml-lg-6">
                <div
                  className="d-flex font-selling-points flex-direction-column justify-content-start 
            text-white align-items-start mt-4 w-100">
                  <div className="text-hard-glow-white w-100 text-left" glow-text={`Nu rata oportunitatea de a deveni speaker la Find Your Why!`}>
                    {`Nu rata oportunitatea de a deveni speaker la Find Your Why!`}
                  </div>
                </div>

                <div className="text-white mt-3 p-relative">
                  <div className="p1 h5-md font-semi-bold pt-2">{`Ia parte la un eveniment cu adevărat special și fă-ți brandul mai cunoscut în fața a peste 4,000 de spectatori.`}</div>
                </div>

                <div className="description-shape-1 object-soft-glow-red object-hard-glow-red-md mt-4 max-w-100">
                  <div className="p1 h5-md font-semi-bold pt-2 pb-1">{`Cum devii speaker la Find Your Why, cel mai mare eveniment motivațional și de business din România.`}</div>
                </div>

                <div className="text-white mt-4 p-relative">
                  <div className="p1 h4-md">{`Pasul 1. Scrie-ne`}</div>
                  <div className="p1 h5-md font-semi-bold mt-1">{`Scrie-ne un mesaj la adresa contact@findyourwhy.ro cu subiectul 'Devino speaker' și lasă-ne datele tale de contact.`}</div>
                </div>

                <div className="text-white mt-3 p-relative">
                  <div className="p1 h4-md">{`Pasul 2. Te sunăm`}</div>
                  <div className="p1 h5-md font-semi-bold mt-1">{`Unul din reprezentații echipei noștri te contactează pentru a discuta mai multe detalii`}</div>
                </div>

                <div className="text-white mt-3 p-relative">
                  <div className="p1 h4-md">{`Pasul 3. Devii speaker`}</div>
                  <div className="p1 h5-md font-semi-bold mt-1">{`Finalizăm ultimele detalii.`}</div>
                </div>

              </div>
            </div>

          </div>
        </>

      <div className="p-relative">
        <div className="container mt-5">
          <div className="d-flex flex-direction-column align-items-center text-white text-center animate__animated animate__fadeInDown reveal-on-scroll">
            <div className="h2 h1-md">Vei împărți scena cu legende din sport, business și showbiz</div>
            <div className="p1 h4-md mt-2">Aruncă o privire pe lista de speakeri confirmați</div>
          </div>

          <div className="d-flex justify-content-evenly flex-wrap text-white">
            {allSpeakers.map((speaker, index) => {
              return (
                <div className="mt-5 mr-2 ml-2 " key={"SpeakerThumbnail-becomeSpeakerPage-" + index}>
                  <SpeakerThumbnail speaker={speaker}></SpeakerThumbnail>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="p-relative mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default BecomeSpeaker;
