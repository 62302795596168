import "./legal.scss";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import Footer from "../../components/footer/footer";

function AcordNewsletter() {
  return (
    <div className="Home">
      <CustomCursor />

      <div className="p-relative mt-7 text-white font-normal">
        <div className="container">
          <h1 className="h0 text-hard-glow-white" glow-text="Acord de comunicare comercială">
            Acord de comunicare comercială
          </h1>
          <h5
            className="h5 text-hard-glow-white"
            glow-text="Acord de comunicare comercială a website-ului 'https://findyourwhy.ro/'">
            Acord de comunicare comercială a website-ului 'https://findyourwhy.ro/'
          </h5>

          <div className="mt-4">
            {`
Prin accesarea butonului de abonare la Newsletter ofer acordul meu pentru transmiterea
de comunicări comerciale din partea Find Your Why (Flony Motivation S.R.L.) către adresa
mea de email completată în legatură cu ofertele, promoțiile, știrile și proiectele oferite
legate de evenimentele organizate de Flony Motivation SRL.
`}
          </div>

          <div className="mt-2">
            {`
Înțeleg că am dreptul`}
          </div>

          <div className="mt-2 bullet-point-left">
            {`
de a renunța la acordul dat`}
          </div>

          <div className="mt-2 bullet-point-left">
            {`
de a solicita modificarea datelor de contact`}
          </div>

          <div className="mt-2 bullet-point-left">
            {`
de a solicita stergerea datelor mele deținute de Flony Motivation S.R.L. printr-o solicitare scrisă pe email la ______________ sau printr-o notificare trimisă la datele de
contact.`}
          </div>

          <div className="mt-2">
            {`
Prin acest acord mă oblig să mă abțin de la orice plângere sau raportare de comunicare
nesolicitată (sau raportare SPAM) pentru orice comunicare primită din partea Flony
Motivation S.R.L. atât timp cât nu am apelat la renunțarea acordului dat.`}
          </div>



        </div>
      </div>

      <div className="p-relative mt-7">
        <Footer />
      </div>
    </div>
  );
}

export default AcordNewsletter;
