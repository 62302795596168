export const salesDeadline = new Date("2024-01-31T23:59:59");
export const eventDates = [new Date("2024-04-19T23:59:59"), new Date("2024-04-20T23:59:59")];

export const activePriceTimeline = 0;
//
// activePriceTimeline possible values
// 0 = Early Bird
// 1 = Regular
// 2 = Last minute
//
export const promotionNames = ["Early Bird", "Regular", "Last minute"];
export const ticketsUrl = "https://bilete.findyourwhy.ro/";

export const packageOffers = [
  {
    title: "Bilet Standard",
    points: [
      "Acces la show ZIUA 1 și ZIUA 2",
      "Acces la discursurile speakerilor din ziua aleasă",
      "Acces la 2 concerte",
      "Experiența unui show motivațional & de business unic în România",
      "Selecția locului în categoria Standard",
      "Networking",
      "Spectacol de magie",
      "Acces la expozițiile din cadrul show-ului",
      "Orchestră live & multe surprize",
      "Networking cu personalități de elită",
      "Certificat de participare FIND YOUR WHY",
      "Coffee break cu DJ live set",
    ],
    missedOutPoints: [
      "Acces la show ZIUA 1 și ZIUA 2",
      "Acces la discursurile celor 20+ speakeri iconici",
      "Acces la 4 concerte",
      "Networking exclusiv",
      "Red carpet entrance",
      "Agendă personalizată",
      "Prânz pregătit de chef",
      "Acces în Diamond Lounge",
      "Photocorner pentru fotografii cu speakerii",
      "Goodie bag: rucsac smart, tricou și 3 cărți",
      "Tombolă: Vacanță 5 zile în Dubai (avion + hotel)",
      "Cină privată cu 3 speakeri Find Your Why",
    ],
    id: "0-standard",
    isOnSale: true,
    soldOutPercentage: "80",
    prices: [
      {
        type: "Early bird",
        salePrice: "249",
        price: "700",
        salePriceForTwoDays: "249",
        priceForTwoDays: "1300",
        discount: "80%",
      },
      {
        type: "Regular",
        salePrice: "700",
        price: "700",
      },
      {
        type: "Last minute",
        // salePrice: "700",
        price: "700",
      },
    ],
    currency: "RON",
    buttonLabel: "Rezerv acum",
    buttonColor: "black-white",
    ribbonType: "",
    color: "white",
    isMainPackage: false,
  },
  {
    title: "Bilet VIP",
    points: [
      "Acces la show ZIUA 1 și ZIUA 2",
      "Acces la discursurile celor 20+ speakeri iconici",
      "Acces la 4 concerte",
      "Experiența unui show motivațional & de business unic în România",
      "Selecția locului în categoria VIP",
      "Networking VIP",
      "Spectacol de magie",
      "Acces la expozițiile din cadrul show-ului",
      "Orchestră live & multe surprize",
      "Certificat de participare FIND YOUR WHY",
      "Coffee break cu DJ live set",
      "Lunchbox",
      "Red carpet entrance",
      "Agendă personalizată",
    ],
    missedOutPoints: [
      "Networking exclusiv",
      "Prânz pregătit de chef",
      "Acces în Diamond Lounge",
      "Photocorner pentru fotografii cu speakerii",
      "Goodie bag: rucsac smart, tricou și 3 cărți",
      "Tombolă: Vacanță 5 zile în Dubai (avion + hotel)",
      "Cină privată cu 3 speakeri Find Your Why",
    ],
    id: "1-vip",
    isOnSale: true,
    soldOutPercentage: "59",
    prices: [
      {
        type: "Early bird",
        salePrice: "599",
        price: "3.300",
        discount: "77%",
      },
      {
        type: "Regular",
        salePrice: "3.300",
        price: "3.300",
      },
      {
        type: "Last minute",
        // salePrice: "3.300",
        price: "3.300",
      },
    ],
    currency: "RON",
    buttonLabel: "Rezerv acum",
    buttonColor: "black-white",
    ribbonType: "best-value",
    color: "red",
    isMainPackage: true,
  },
  {
    title: "Bilet Diamond",
    points: [
      "Acces la show ZIUA 1 și ZIUA 2",
      "Acces la discursurile celor 20+ speakeri iconici",
      "Acces la 4 concerte",
      "Experiența unui show motivațional & de business unic în România",
      "Selecția locului în categoria Diamond",
      "Networking exclusiv",
      "Spectacol de magie",
      "Acces la expozițiile din cadrul show-ului",
      "Coffee break cu DJ live set",
      "Orchestră live & multe surprize",
      "Certificat de participare FIND YOUR WHY",
      "Red carpet entrance",
      "Agendă personalizată",
      "Prânz pregătit de chef",
      "Acces în Diamond Lounge",
      "Photocorner pentru fotografii cu speakerii",
      "Goodie bag: rucsac smart, tricou și 3 cărți",
      "Tombolă: Vacanță 5 zile în Dubai (avion + hotel)",
    ],
    missedOutPoints: [
      "Cină privată cu 3 speakeri Find Your Why",
    ],
    id: "2-diamond",
    isOnSale: true,
    soldOutPercentage: "15",
    prices: [
      {
        type: "Early bird",
        salePrice: "1.490",
        price: "8.900",
        discount: "80%",
      },
      {
        type: "Regular",
        salePrice: "8.900",
        price: "8.900",
      },
      {
        type: "Last minute",
        // salePrice: "8.900",
        price: "8.900",
      },
    ],
    currency: "RON",
    buttonLabel: "Rezerv acum",
    buttonColor: "black-white",
    ribbonType: "",
    color: "white",
    isMainPackage: false,
  },
  {
    title: "Bilet Supreme",
    points: [
      "Cină privată cu 3 speakeri Find Your Why",
      "Acces la show ZIUA 1 și ZIUA 2",
      "Acces la discursurile celor 20+ speakeri iconici",
      "Acces la 4 concerte",
      "Experiența unui show motivațional & de business unic în România",
      "Selecția locului în categoria Supreme",
      "Networking cu personalități de elită",
      "Spectacol de magie",
      "Acces la expozițiile din cadrul show-ului",
      "Coffee break cu DJ live set",
      "Orchestră live & multe surprize",
      "Certificat de participare FIND YOUR WHY",
      "Red carpet entrance",
      "Agendă personalizată",
      "Prânz pregătit de chef",
      "Acces în Diamond Lounge",
      "Photocorner pentru fotografii cu speakerii",
      "Goodie bag: rucsac smart, tricou și 3 cărți",
      "Tombolă: Vacanță 5 zile în Dubai (avion + hotel)",
    ],
    id: "3-supreme",
    isOnSale: true,
    soldOutPercentage: "5",
    prices: [
      {
        type: "Early bird",
        salePrice: "4.499",
        price: "13.000",
        discount: "55%",
      },
      {
        type: "Regular",
        salePrice: "13.000",
        price: "13.000",
      },
      {
        type: "Last minute",
        // salePrice: "13.000",
        price: "13.000",
      },
    ],
    currency: "RON",
    buttonLabel: "Rezerv acum",
    buttonColor: "black-white",
    ribbonType: "",
    color: "white",
    isSupreme: true,
    isMainPackage: false,
  },
];

export const eventDaysOptionValues = [
  { value: "1", name: "Ziua 1" },
  { value: "2", name: "Ziua 2" },
  { value: "3", name: "Ziua 1 și Ziua 2" },
];

export const allSpeakers = [
  {
    name: "Denisa Tănase",
    title: "Antreprenor, my Geisha",
    photo: require("./../assets/speakers/denisa-tanase.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/9S3hXB_M7fc",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/9S3hXB_M7fc",
    shortDescription:
      "„Sora, mama, tata nu priveau cu ochi buni faptul că eu la 30 de ani mă apuc să fac altceva după o viață de muncă. În primul an și jumătate, i-am convins, însă, că este o direcție bună”",
    fullDescription:
      "După aproape 20 de ani petrecuți în trupa Bambi, Denisa s-a înarmat cu curaj și a pornit pe drumul antreprenorial. Cu o investiție inițială de 500 de euro, antreprenoarea a pus bazele unui imperiu, astăzi format din peste 50 de magazine din 12 țări europene. Românca a devenit milionară în doar 3 ani, iar my Geisha concurează cu branduri iconice precum Chanel, Dior sau Yves Saint Laurent.",
    quote:
      "„Sora, mama, tata nu priveau cu ochi buni faptul că eu la 30 de ani mă apuc să fac altceva după o viață de muncă. În primul an și jumătate, i-am convins, însă, că este o direcție bună”",
    url: "denisa-tanase",
    // days: ["Ziua 1", "Ziua 2"],
  },
  {
    name: "Sebastian Dobrincu",
    title: "Antreprenor Tech, Artist, Investitor Imperiul Leilor",
    photo: require("./../assets/speakers/sebastian-dobrincu.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/0DYqljxgBbQ",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/FGFXlxLiFXo",
    shortDescription:
      "„Ce poate fi mai frumos decât să te autodepășești? Eu asta am căutat toată viața, asta caut în continuare.”",
    fullDescription:
      "Fără să provină dintr-o familie înstărită și pasionat de tehnologie încă de la 11 ani, Sebastian s-a dezvoltat rapid ca antreprenor tech și tânăr milionar. O exmatriculare cu doar câteva luni înainte de BAC l-a dus în SUA unde viața i-a pregătit noi oportunități surprinzătoare. Povestea lui continuă și te invităm să o descoperi la Find Your Why.",
    url: "sebastian-dobrincu",
    quote:
      "„Ce poate fi mai frumos decât să te autodepășești? Eu asta am căutat toată viața, asta caut în continuare.”",
    // days: ["Ziua 1"],
  },
  {
    name: "Dorian Popa",
    title: "Artist & Vlogger român",
    photo: require("./../assets/speakers/dorian-popa.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/YjfuVhpLLXU",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/hMG1nnd4Yqc",
    shortDescription:
      "„Informația înseamnă putere și cel mai frumos mod de a obține informație este distrandu-te, făcând-o alături de cei care care îți insufla ceea ce îți dorești în viață.”",
    fullDescription:
      "Poți spune orice despre Dorian Popa, mai puțin 1) că nu ai auzit de el și 2) că nu este un om muncitor. De-a lungul celor 12 ani de carieră, Dorian a jucat în 9 filme și seriale, a lansat 33 de single-uri, a realizat peste 1,300 de videouri postate pe YouTube, a construit un cartier rezidențial și a lansat un joc video. O poveste despre munca, perseverența și disciplina care sunt deseori eclipsate de glitter-ul prosperității și faimei.",
    url: "dorian-popa",
    quote:
      "„Informația înseamnă putere și cel mai frumos mod de a obține informație este distrandu-te, făcând-o alături de cei care care îți insufla ceea ce îți dorești în viață.”",
    // days: ["Ziua 2"],
  },
  {
    name: "Adrian Nartea",
    title: "Actor, serialul Vlad",
    photo: require("./../assets/speakers/adrian-nartea.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/g1yu8XvNBT0",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/R6y3N7mVk1E",
    shortDescription:
      "„Eu am un principiu pe care l-am dobândit după experiențele mele de viață, ca să spun așa, în care tu te prioritizezi pe tine înaintea tuturor. Pentru că, în momentul în care tu ești bine, ceilalți sunt bine.”",
    fullDescription:
      "Adrian cocheta cu lumea actoriei încă din 2007 când a primit un rol în celebrul serial La Bloc, însă soarta nu ține cont de planuri. A primit rolul care l-a consacrat la vârsta de 38 de ani odată cu lansarea serialului Vlad. Cum vede viața un om care s-a întâlnit cu moartea? Ce contează cu adevărat atunci când ești pe muchie de cuțit? Și unde găsești motivația să mergi mai departe? Descoperă extraordinara poveste a unui om care te va inspira.",
    url: "adrian-nartea",
    quote:
      "„Eu am un principiu pe care l-am dobândit după experiențele mele de viață, ca să spun așa, în care tu te prioritizezi pe tine înaintea tuturor. Pentru că, în momentul în care tu ești bine, ceilalți sunt bine.”",
    // days: ["Ziua 2"],
  },
  {
    name: "Mircea Căpăţînă",
    title: "Imperiul Leilor / co-fondator SmartBill",
    photo: require("./../assets/speakers/mircea-capatina.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/wc5WBGtQF-w",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/wc5WBGtQF-w",
    shortDescription:
      "„Succesul în afaceri, la fel ca în viață, este o călătorie continuă de învățare, inovație și adaptare. Adevărata împlinire vine atunci când transformi o viziune într-o realitate care îmbunătățește viețile oamenilor.”",
    fullDescription:
      "Mircea Căpăţînă, un antreprenor remarcabil din Timișoara și absolvent al Universității Politehnica, este o figură emblematică în lumea afacerilor românești. Fondator al renumitei companii de software contabil Smart Bill și investitor în domeniul criptomonedelor, Căpăţînă a devenit un nume respectat în antreprenoriat și tehnologie. Cu o experiență de peste 14 ani, a transformat Smart Bill într-o companie de succes cu peste 55 de angajați și 50.000 de clienți. Participarea sa în juriul 'Imperiul Leilor' subliniază expertiza și viziunea sa în afaceri.",
    quote:
      "„Succesul în afaceri, la fel ca în viață, este o călătorie continuă de învățare, inovație și adaptare. Adevărata împlinire vine atunci când transformi o viziune într-o realitate care îmbunătățește viețile oamenilor.”",
    url: "mircea-capatina",
    // days: ["Ziua 1", "Ziua 2"],
  },
  {
    name: "Carmen Brumă",
    title: "Expert în fitness și nutriție / Antreprenor",
    photo: require("./../assets/speakers/carmen-bruma.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/5lp019veMTs",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/5lp019veMTs",
    shortDescription:
      "„Echilibrul în viață începe cu alegeri sănătoase și o minte deschisă. Succesul personal și profesional este strâns legat de modul în care ne îngrijim de corpul și mintea noastră.”",
    fullDescription:
      "Carmen Brumă este cunoscută pentru rolul său de prezentatoare TV, autor și expert în fitness și nutriție. A devenit populară prin promovarea unui stil de viață sănătos și echilibrat și a scris cărți pe această temă. Abordarea sa holistică asupra sănătății și fitness-ului a inspirat multe persoane să adopte obiceiuri mai sănătoase. Contribuțiile ei în domeniul sănătății și fitness-ului au avut un impact semnificativ asupra publicului din România.",
    quote:
      "„Echilibrul în viață începe cu alegeri sănătoase și o minte deschisă. Succesul personal și profesional este strâns legat de modul în care ne îngrijim de corpul și mintea noastră.”",
    url: "carmen-bruma",
    // days: ["Ziua 1", "Ziua 2"],
  },
  {
    name: "Tedy Necula",
    title: "Inspirational Film Director",
    photo: require("./../assets/speakers/tedy-necula.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/CBuDJSIPheU",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/sGtk7BnJOXY",
    shortDescription:
      "„Orice limitare este doar o iluzie, Ne găsim De Ce-ul experimentând. E foarte ușor să te simți pierdut, să nu știi ce e cu tine. Până nu experimentezi, nu are cum să îți iasă.”",
    fullDescription:
      "De mic copil, Tedy a învățat că viața nu e mereu dreaptă. La naștere a primit scor APGAR 1, la 8 luni a fost diagnosticat cu tetrapareză, iar la 5 ani și-a pierdut mama. Însă cărțile care i-au fost împărțite nu l-au împiedicat să devină regizor, să creeze peste 40 de filme documentare, să producă filme inspiraționale și să țină un discurs în Camera Deputaților. Descoperă povestea care îți va schimba total perspectiva asupra vieții.",
    quote:
      "„Orice limitare este doar o iluzie, Ne găsim De Ce-ul experimentând. E foarte ușor să te simți pierdut, să nu știi ce e cu tine. Până nu experimentezi, nu are cum să îți iasă.”",
    url: "tedy-necula",
    // days: ["Ziua 1", "Ziua 2"],
  },
  {
    name: "F.Charm",
    title: "Artist și Producător",
    photo: require("./../assets/speakers/f-charm.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/2Dbt39cE-0o",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/yqOPNvO4R-g",
    shortDescription:
      "„Redescoperă drumul spre succes pe care toți il căutăm. Este un drum de autodescoperire și de realizare a potențialului tău maxim.”",
    fullDescription:
      "F.Charm, cunoscut pentru pasiunea sa pentru muzică și abilitatea de a se reinventa constant, a traversat numeroase provocări pentru a ajunge unde este astăzi. Și-a început cariera de la zero, croindu-și drumul spre succes prin talent, muncă asiduă și o viziune unică asupra industriei muzicale. Cu numeroase hituri la activ și o afacere prosperă în domeniul muzicii, F.Charm îți va povesti cum a transformat fiecare obstacol într-o treaptă către succes.",
    quote: "„Redescoperă drumul spre succes pe care toți il căutăm. Este un drum de autodescoperire și de realizare a potențialului tău maxim.”",
    url: "f-charm",
    // days: ["Ziua 1", "Ziua 2"],
  },
  {
    name: "Anunțăm curând",
    title: "Jurnalist și prezentator TV",
    photo: require("./../assets/speakers/anonymous-speaker-f.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Jurnalistă & icon românesc",
    photo: require("./../assets/speakers/anonymous-speaker-m.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Artist & Vlogger",
    photo: require("./../assets/speakers/anonymous-speaker-f.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Personalitate media",
    photo: require("./../assets/speakers/anonymous-speaker-m.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Om de afaceri, producător și artist",
    photo: require("./../assets/speakers/anonymous-speaker-f.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Antreprenor",
    photo: require("./../assets/speakers/anonymous-speaker-m.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Vlogger",
    photo: require("./../assets/speakers/anonymous-speaker-f.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Antreprenor Tech",
    photo: require("./../assets/speakers/anonymous-speaker-m.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Actor",
    photo: require("./../assets/speakers/anonymous-speaker-f.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Antreprenor HoReCa",
    photo: require("./../assets/speakers/anonymous-speaker-m.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Speaker motivațional",
    photo: require("./../assets/speakers/anonymous-speaker-f.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Antreprenor",
    photo: require("./../assets/speakers/anonymous-speaker-m.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
  {
    name: "Anunțăm curând",
    title: "Social media influencer",
    photo: require("./../assets/speakers/anonymous-speaker-f.webp"),
    shortVideoInterviewUrl: "",
    fullVideoInterviewUrl: "",
    shortVhortDescription: "„Mai multe detalii în curând”",
    shortDescription: "„Mai multe detalii în curând”",
    fullDescription: "",
    quote: "„Mai multe detalii în curând”",
    url: "anonymous-speaker",
  },
];

export const allEntertainers = [
  {
    name: "Smiley",
    title: "În concert",
    photo: require("./../assets/speakers/smiley.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/9S3hXB_M7fc",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/9S3hXB_M7fc",
    shortDescription: "",
    fullDescription: "",
    quote: "Va cânta pe scena Find Your Why",
    url: "smiley",
  },
  {
    name: "Magicianul Robert Tudor",
    title: "Iluzionist și Entertainer",
    photo: require("./../assets/speakers/magicianul-robert-tudor.webp"),
    shortVideoInterviewUrl: "https://www.youtube.com/embed/VGerU0qiWMM",
    fullVideoInterviewUrl: "https://www.youtube.com/embed/VGerU0qiWMM",
    shortDescription: "„Când mi-am dat jos masca de clown eram ca Superman fără pelerină”",
    fullDescription:
      "Magicianul Robert Tudor îți va arăta că magia nu este doar un spectacol, ci și o artă care vorbește despre posibilități infinite. De la primele trucuri învățate în copilărie și până la performanțele sale impresionante pe scene naționale și internaționale, Robert Tudor a căutat mereu să depășească limitele realității. Vei descoperi cum perseverența, creativitatea și dorința de a uimi publicul l-au transformat într-unul dintre cei mai respectați iluzioniști.",
    quote: "„Când mi-am dat jos masca de clown eram ca Superman fără pelerină”",
    url: "magicianul-robert-tudor",
  },
  {
    ...allSpeakers.find((s) => s.url === "f-charm"),
    title: "În concert",
  }
];

export const frontPageSpeakers = [
  allSpeakers.find((s) => s.url === "denisa-tanase"),
  allSpeakers.find((s) => s.url === "sebastian-dobrincu"),
  allSpeakers.find((s) => s.url === "mircea-capatina"),
  allSpeakers.find((s) => s.url === "adrian-nartea"),
  allSpeakers.find((s) => s.url === "carmen-bruma"),
  allSpeakers.find((s) => s.url === "dorian-popa"),
];

export const visibleFrontPageInterviews = [
  allSpeakers.find((s) => s.url === "sebastian-dobrincu"),
  allSpeakers.find((s) => s.url === "adrian-nartea"),
  allSpeakers.find((s) => s.url === "tedy-necula"),
  allSpeakers.find((s) => s.url === "dorian-popa"),
];

export const hiddenFrontPageInterviews = [
  allSpeakers.find((s) => s.url === "f-charm"),
  allSpeakers.find((s) => s.url === "denisa-tanase"),
  allSpeakers.find((s) => s.url === "mircea-capatina"),
  allSpeakers.find((s) => s.url === "carmen-bruma"),
];

export const floatingSideBulletSpeakers = [
  allEntertainers.find((s) => s.url === "smiley"),
  ...allSpeakers.filter((s) => s.url !== "anonymous-speaker"),
  allEntertainers.find((s) => s.url === "magicianul-robert-tudor"),
];

export const confirmedSpeakers = [...allSpeakers.filter((s) => s.url !== "anonymous-speaker")];

export const unconfirmedSpeakers = [...allSpeakers.filter((s) => s.url === "anonymous-speaker")];

export const contactPhoneNumbers = ["0757 114 664", "0733 197 782"]