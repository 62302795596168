import { useEffect, useState, useRef } from "react";
import ButtonHero from "../button-hero/button-hero";
import "./entertainer-thumbnail.scss";
import { isMobileDevice } from "../../utils/utils";

function EntertainerThumbnail({ speaker, isHoverable = false }) {
  const [isHovering, setIsHovering] = useState(false);
  const entertainerThumbnailContainer = useRef(null);

  useEffect(() => {
    if (isHovering) {
      entertainerThumbnailContainer.current.classList.add("hovering");
    } else {
      entertainerThumbnailContainer.current.classList.remove("hovering");
    }
  }, [isHovering]);

  const showFrontSide = () => {
    if (!isMobileDevice() && isHoverable) {
      setIsHovering(false);
    }
  };

  const showBackSide = () => {
    if (!isMobileDevice() && isHoverable) {
      setIsHovering(true);
    }
  };

  const switchSides = () => {
    if(isHoverable){
      setIsHovering((v) => !v);
    }
  };

  return (
    <div
      className="speaker-thumbnail-container"
      ref={entertainerThumbnailContainer}
      onMouseEnter={() => showBackSide()}
      onMouseLeave={() => showFrontSide()}
      onBlur={() => showFrontSide()}
      onClick={() => switchSides()}>
      <div className="speaker-thumbnail-background"></div>
      <div className="">
        <img src={speaker.photo} alt={speaker.name} className="speaker-image" width="100%" height="auto" />
      </div>
      <div className="speaker-description">
        <div className="h3">{speaker.name}</div>
        <div className="p1 font-semi-bold mt-2">{speaker.title}</div>
      </div>

      <div className="speaker-behind">
        <div className="p2">{speaker.quote}</div>

        <div className="speaker-cta p2">
          <a href={`/ce-spun-speakerii/${speaker.url}`}>
            <ButtonHero color="white-black" is3d={false} content="Vezi mai mult"></ButtonHero>
          </a>
        </div>
      </div>
    </div>
  );
}

export default EntertainerThumbnail;
