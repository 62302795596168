import React, {useContext, useEffect, useState} from "react";
import {ModalContext} from "./../../../utils/modal/modalContext";
import "./alert-modal.scss";
import DaysSelector from "../../days-selector/days-selector";
import ContactForm from "../../contact-form/contact-form";
import AllSpeakersList from "../../allSpeakersList/all-speakers-list";
import GroupOfferForm from "../../group-offer-form/group-offer-form";

export const alertModalTypes = {
  Confirm: "confirm",
  Success: "success",
  Warning: "warning",
  Error: "error",
  OnlyCancel: "onlyCancel",
  Blocker: "blocker",
  WithValidation: "withValidation",
  SelectDays: "selectDays",
  AllSpeakers: "allSpeakers",
  Contact: "contact",
  GroupOffer: "groupOffer",
  DateChanged: "dateChanged"
};

function AlertModal({
  children,
  title,
  type,
  className = "",
  successLabel = "",
  cancelLabel = "",
  extraData = {},
  onDismiss = () => {},
  onSuccess = () => {},
}) {
  const {handleModal} = useContext(ModalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalHidden, setIsModalHidden] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleDismiss = () => {
    if (type !== alertModalTypes.Blocker) {
      setIsModalOpen(false);
      setTimeout(() => {
        onDismiss();
        return handleModal();
      }, 300);
    }
  };

  const handleSuccess = (args) => {
    setTimeout(() => {
      console.log("handleSuccess args", args);
      onSuccess(args);
      return handleModal();
    }, 300);
  };

  useEffect(() => {
    setIsModalOpen(true);
    // setTimeout(() => {}, 300);
    console.log("className", className);
  }, []);

  useEffect(() => {
    if (isModalOpen) setIsModalHidden(false);
  }, [isModalOpen]);

  const returnModalDividerClass = (type) => {
    switch (type) {
      case "confirm": {
        return "green";
      }
      case "success": {
        return "green";
      }
      case "warning": {
        return "warning";
      }
      case "error": {
        return "warning";
      }
      default: {
        return "";
      }
    }
  };

  const returnModalButtons = (type) => {
    switch (type) {
      case alertModalTypes.Confirm: {
        return (
          <div className="modal-action-area text-center d-flex mt-2 justify-content-around flex-wrap gap-2">
            <div
              className={"button cancel-button flex-1 d-flex justify-content-center"}
              onClick={() => {
                handleDismiss();
              }}>
              {cancelLabel || "Cancel"}
            </div>
            <div
              className={`button confirm-button flex-1 d-flex justify-content-center `}
              onClick={() => {
                handleSuccess();
              }}>
              {successLabel || "OK"}
            </div>
          </div>
        );
      }
      case alertModalTypes.Success: {
        break;
      }
      case alertModalTypes.Warning: {
        return (
          <div className="modal-action-area text-center d-flex mt-2 justify-content-around flex-wrap gap-2">
            <div
              className={"button cancel-button flex-1 d-flex justify-content-center"}
              onClick={() => {
                handleDismiss();
              }}>
              {cancelLabel || "Cancel"}
            </div>
            <div
              className={`button confirm-button flex-1 d-flex justify-content-center `}
              onClick={() => {
                handleSuccess();
              }}>
              {successLabel || "Proceed"}
            </div>
          </div>
        );
      }
      case alertModalTypes.Error: {
        return (
          <div className="modal-action-area text-center d-flex mt-2 justify-content-center">
            <div
              className={`button confirm-button flex-1 d-flex justify-content-center `}
              onClick={() => {
                handleSuccess();
              }}>
              {successLabel || "OK"}
            </div>
          </div>
        );
      }
      case alertModalTypes.OnlyCancel: {
        return (
          <div className="modal-action-area text-center d-flex mt-2 justify-content-center">
            <div
              className={"button cancel-button flex-1 d-flex justify-content-center"}
              onClick={() => {
                handleDismiss();
              }}>
              {cancelLabel || "Cancel"}
            </div>
          </div>
        );
      }
      case alertModalTypes.Blocker: {
        return <></>;
      }
      case alertModalTypes.SelectDays: {
        return (
          <div class="pl-2 pr-2">
            <div class="w-100 d-flex justify-content-center">
              <DaysSelector isValidated={setIsFormValid} onSubmit={handleSuccess} onDismiss={handleDismiss} successLabel={successLabel} cancelLabel={cancelLabel} extraData={extraData}/>
            </div>
          </div>
        );
      }
      case alertModalTypes.Contact: {
        return (
          <div class="pl-2 pr-2">
            <div class="w-100 d-flex justify-content-center">
              <ContactForm isValidated={setIsFormValid} onSubmit={handleSuccess} onDismiss={handleDismiss} setForm/>
            </div>
          </div>
        );
      }
      case alertModalTypes.GroupOffer: {
        return (
          <div class="pl-2 pr-2">
            <div class="w-100 d-flex justify-content-center">
              <GroupOfferForm isValidated={setIsFormValid} onSubmit={handleSuccess} onDismiss={handleDismiss} setForm/>
            </div>
          </div>
        );
      }
      case alertModalTypes.DateChanged: {
        return (
          <div class="p-2 pt-0 pb-3">
            <div class="w-100 d-flex justify-content-center">

            Am mutat data evenimentului. Vom anunța în curând noua dată.
            <br></br>
            <br></br>
            Dorim să aducem mai mulți speakeri de top și să organizăm surprize suplimentare.
            <br></br>
            <br></br>
            Mulțumim din suflet pentru înțelegere.
            </div>
          </div>
        );
      }

      case alertModalTypes.AllSpeakers: {
        return (
          <div class="pl-1 pr-1 pl-md-3 pr-md-3 box-sizing-border p-relative">
            <div class="w-100 d-flex justify-content-center box-sizing-border">
              <AllSpeakersList/>
            </div>
            <div className="modal-action-area text-center d-flex justify-content-center flex-wrap gap-2">
              <div
                className={`button confirm-button flex-1 d-flex justify-content-center`}
                onClick={() => {
                  handleSuccess();
                }}>
                {successLabel || "OK"}
              </div>
            </div>
          </div>
        );
      }

      case alertModalTypes.WithValidation: {
        return <></>;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <div className="modal-backdrop" onClick={handleDismiss}></div>
      <div
        className={`modal-content-container ${type}-modal ${
          isModalHidden ? "hidden-modal" : isModalOpen ? "opened-modal" : "closed-modal"
        } ${className}`}>
        <div className={`modal-header d-flex justify-content-center p-2 ${type}-header`}>
          <div className="text-bold h5 text-center text-underline underline-color-black underline-variant-8">{title}</div>
          {type !== alertModalTypes.Blocker ? (
            <div onClick={() => handleDismiss()} className="close-icon" tabIndex={0}></div>
          ) : (
            <></>
          )}
        </div>

        <div className={`modal-divider ${returnModalDividerClass(type)}`}></div>

        <div className="modal-content p-2">
          <div className="">{children}</div>
        </div>
        {returnModalButtons(type)}
      </div>
    </>
  );
}

export default AlertModal;
