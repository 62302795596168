import "./google-map-marker.scss";

function GoogleMapMarker({onClick}) {
  return (
    <div onClick={onClick}>
      <div className="marker-container">
        <img
          className="marker-image"
          width="120px"
          src={require("./../../assets/images/location-pin.png")}
          alt="Romexpo"></img>
      </div>
    </div>
  );
}

export default GoogleMapMarker;
