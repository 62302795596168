import "./legal.scss";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import Footer from "../../components/footer/footer";

function TermsConditions() {
  return (
    <div className="Home">
      <CustomCursor />

      <div className="p-relative mt-7 text-white">
        <div className="container">
          <h1 className="h0 text-hard-glow-white" glow-text="Termeni și condiții">
            Termeni și condiții
          </h1>

          <div className="mt-4">
            {`Terms and conditions here`}
          </div>
        </div>
      </div>

      <div className="p-relative mt-7">
        <Footer />
      </div>
    </div>
  );
}

export default TermsConditions;
