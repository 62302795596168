import "./speakers.scss";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import Footer from "../../components/footer/footer";
import { useContext, useEffect, useState } from "react";
import { allEntertainers, allSpeakers, confirmedSpeakers, unconfirmedSpeakers } from "../../utils/constants";
import { useParams } from "react-router-dom";
import SpeakerThumbnail from "../../components/speaker-thumbnail/speaker-thumbnail";
import ButtonHero from "../../components/button-hero/button-hero";
import EntertainerThumbnail from "../../components/entertainer-thumbnail/entertainer-thumbnail";
import { isMobileDevice } from "../../utils/utils";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../../components/modals/alert-modal/alert-modal";

function Speakers() {
  const [currentSpeaker, setCurrentSpeaker] = useState(null);
  const { speakerId } = useParams();
  const { handleModal } = useContext(ModalContext);

  useEffect(() => {
    console.log("speakerId", speakerId);
    setCurrentSpeaker(allSpeakers.find((s) => s.url === speakerId) || null);
  }, [speakerId]);

  const openContactModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={reject}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };
  return (
    <div className="">
      <div id="top"></div>
      <CustomCursor />

      {currentSpeaker !== null ? (
        <>
          <div className="p-relative">
            <div className="d-md-flex justify-content-start container">
              <div className="left-big-shape-container d-flex h-100vh flex-direction-column justify-content-center text-white h2 h0-lg text-center text-md-left pl-0 pl-md-0">
                <div className="left-big-shape"></div>
                <div className="title-image-container">
                  <img src={currentSpeaker.photo} alt={currentSpeaker.name}></img>
                </div>
              </div>

              <div className="ml-2 ml-lg-6">
                <div
                  className="d-flex font-selling-points flex-direction-column justify-content-start 
            text-white align-items-start mt-4 w-100">
                  <div className="text-hard-glow-white w-100 text-left" glow-text={currentSpeaker.name}>
                    {currentSpeaker.name}
                  </div>
                </div>

                <div
                  className="d-flex font-selling-points flex-direction-column justify-content-start 
            text-white align-items-start mt-2 w-100">
                  <div className="h5 text-hard-glow-white w-100 text-left" glow-text={currentSpeaker.title}>
                    {currentSpeaker.title}
                  </div>
                </div>

                {currentSpeaker?.fullDescription ? (
                  <>
                    <div className="description-shape-1 object-soft-glow-red object-hard-glow-red-md mt-4 max-w-100">
                      <div className="p1 h5-md font-semi-bold pt-2 pb-2">{currentSpeaker.fullDescription}</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="text-white mt-4 p-relative">
                  <div className="p1 h5-md font-semi-bold pt-2 pb-3">{currentSpeaker.quote}</div>
                </div>

                <div className="text-white mt-4">
                  <ButtonHero color="red-white" is3d={false} link="/#bilete" content="Cumpără bilet"></ButtonHero>
                </div>
              </div>
            </div>

            {currentSpeaker?.fullVideoInterviewUrl ? (
              <>
                <div className="small-container mt-5">
                  <div className="d-flex flex-direction-column align-items-center text-white text-center animate__animated animate__fadeInDown reveal-on-scroll">
                    <div className="h2 h1-md">Interviu {currentSpeaker.name}</div>
                  </div>
                  <div className="interview-media-container mt-4">
                    <iframe
                      width="100%"
                      height="100%"
                      src={`${currentSpeaker.fullVideoInterviewUrl}?autoplay=0&rel=0&${
                        isMobileDevice() ? "mute=0" : "mute=1"
                      }&enablejsapi=1;controls=0`}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen></iframe>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="p-relative">
        <div className="container mt-5">
          <div className="d-flex flex-direction-column align-items-center text-white text-center">
            <div className="h2 h1-md">Speakeri</div>
          </div>

          <div className="d-flex justify-content-evenly flex-wrap text-white">
            {confirmedSpeakers.map((speaker, index) => {
              return (
                <div className="mt-5 mr-2 ml-2 " key={"SpeakerThumbnail-speakersPage-" + index}>
                  <SpeakerThumbnail speaker={speaker}></SpeakerThumbnail>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="p-relative">
        <div className="container mt-5">
          <div className="d-flex flex-direction-column align-items-center text-white text-center">
            <div className="h2 h1-md">Atmosferă întreținută de</div>
          </div>

          <div className="d-flex justify-content-evenly flex-wrap text-white">
            {allEntertainers.map((speaker, index) => {
              return (
                <div className="mt-5 mr-2 ml-2 " key={"confirmed_EntertainerThumbnail-speakersPage-" + index}>
                  <EntertainerThumbnail speaker={speaker}></EntertainerThumbnail>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="p-relative">
        <div className="d-flex justify-content-center align-items-center mt-4">
          <ButtonHero
            color="red-white"
            is3d={false}
            content="Propune un speaker"
            scale="0.8"
            width="500px"
            onClick={openContactModal}></ButtonHero>
        </div>
      </div>

      <div className="p-relative">
        <div className="container mt-5">
          <div className="d-flex flex-direction-column align-items-center text-white text-center">
            <div className="h2 h1-md">Coming soon</div>
          </div>

          <div className="d-flex justify-content-evenly flex-wrap text-white">
            {unconfirmedSpeakers.map((speaker, index) => {
              return (
                <div className="mt-5 mr-2 ml-2 " key={"unconfirmed_SpeakerThumbnail-speakersPage-" + index}>
                  <SpeakerThumbnail speaker={speaker}></SpeakerThumbnail>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="p-relative mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default Speakers;
