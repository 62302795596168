import "./sponsors.scss";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import Footer from "../../components/footer/footer";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ButtonHero from "../../components/button-hero/button-hero";
import {isMobileDevice} from "../../utils/utils";

function Sponsors() {
  const [currentSponsor, setCurrentSponsor] = useState(null);
  const {sponsorId} = useParams();

  useEffect(() => {
    console.log("sponsorId", sponsorId);
  }, [sponsorId]);

  return (
    <div className="">
      <CustomCursor />

      <div className="mt-6 mb-6 text-white">
        <h1>{isMobileDevice() ? "is mobile device" : "is desktop device"}</h1>
      </div>
      <div className="p-relative mt-5">
        <Footer />
      </div>
    </div>
  );
}

export default Sponsors;
