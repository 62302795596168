import { useContext, useEffect, useState } from "react";
import AnimationRevealer from "../../components/animation-revealer/animation-revealer";
import ExpandablePolygon from "../../components/expandable-polygon/expandable-polygon";
import Hero from "../../components/hero/hero";
import "./home.scss";
import "animate.css";
import SponsorsBar from "../../components/sponsors-bar/sponsors-bar";
import ButtonHero from "../../components/button-hero/button-hero";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import SpeakerThumbnail from "../../components/speaker-thumbnail/speaker-thumbnail";
import GoogleMapLocation from "../../components/google-map/google-map";
import Footer from "../../components/footer/footer";
import FloatingSpeakersList from "../../components/floating-speakers-list/floating-speakers-list";
import FloatingMenu from "../../components/floating-menu/floating-menu";
import Tickets from "../../components/tickets/tickets";
import AlertModal, { alertModalTypes } from "../../components/modals/alert-modal/alert-modal";
import { ModalContext } from "../../utils/modal/modalContext";
import {
  activePriceTimeline,
  allEntertainers,
  allSpeakers,
  visibleFrontPageInterviews,
  frontPageSpeakers,
  promotionNames,
  salesDeadline,
  hiddenFrontPageInterviews,
} from "../../utils/constants";
import GlowRevealer from "../../components/glow-revealer/glow-revealer";
import { useLocation } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import EntertainerThumbnail from "../../components/entertainer-thumbnail/entertainer-thumbnail";
import { isMobileDevice } from "../../utils/utils";

const stakeholdersText = [
  {
    title: "Antreprenorii",
    content:
      "Care s-au blocat la un nivel de business și caută inspirație de la cei mai puternici oameni de afaceri din România.",
    photo: require("./../../assets/images/stakeholders/antreprenori-2.webp"),
    color: "white",
    side: "left",
  },
  {
    title: "Fondatorii de startup",
    content: "Care trec prin provocarea carierei lor și prețuiesc un networking de calitate la greutatea lui în aur.",
    photo: require("./../../assets/images/stakeholders/fondatori.webp"),
    color: "red",
    side: "right",
  },
  {
    title: "Tinerii excepționali",
    content:
      "Care abia au pornit în business, în carieră sau în viață și vor să învețe performanța de la cei mai buni.",
    photo: require("./../../assets/images/stakeholders/tineri.webp"),
    color: "white",
    side: "left",
  },
  {
    title: "Angajații",
    content:
      "Care știu că pot mai mult și sunt mai mult. Sunt gata să își găsească motivația și DE CE-ul să își transforme viața.",
    photo: require("./../../assets/images/stakeholders/angajati.webp"),
    color: "red",
    side: "right",
  },
];

function Home() {
  const { hash } = useLocation();
  const { handleModal } = useContext(ModalContext);
  const [isRegisteringToNewsletter, setIsRegisteringToNewsletter] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
    reset,
    setValue,
    setError,
  } = useForm({ mode: "onChange" });
  const [emailInput, setEmailInput] = useState("");
  const [showMoreInterviews, setShowMoreInterviews] = useState(false);

  useEffect(() => {
    console.log("hash changed", hash);
    if (hash === "#bilete") {
      document.getElementById("bilete").scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  useEffect(() => {
    console.log("date changed");
    if(!localStorage.getItem("wasAnnouncedAboutDateChange")){
      openDateChangedModal()
    }
  }, []);  
  
  const openDateChangedModal = () => {
    localStorage.setItem("wasAnnouncedAboutDateChange", true);
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.DateChanged}
          className="modal-wide"
          title="Am mutat data evenimentului !"
          successLabel="OK"
          cancelLabel="Anulează"
          onDismiss={reject}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  const openAllSpeakersModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.AllSpeakers}
          className="modal-widest"
          title="Toți speakerii de la eveniment"
          successLabel="Propune un speaker"
          cancelLabel="Anulează"
          onDismiss={reject}
          onSuccess={() => {
            setTimeout(() => {
              openContactModal();
            }, 500);
          }}></AlertModal>
      );
    });
  };

  const openContactModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={reject}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  const openGroupOfferModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.GroupOffer}
          className="modal-wide"
          title="Oferta de grup"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={reject}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  const registerToNewsletter = async (args) => {
    try {
      setIsRegisteringToNewsletter(true);
      console.log("Submited", args);
      const functions = getFunctions();
      console.log("functions", functions);
      const obj = {
        email: args?.emailInput,
      };
      await httpsCallable(functions, "subscribeToNewsletter")(obj);
      toast(`Inregistrat cu success !`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "dark",
      });
    } catch (err) {
      console.error(err);
      toast("Nu te-am putut inregistra, incearca din nou !", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
        theme: "dark",
      });
    } finally {
      setIsRegisteringToNewsletter(false);
      setValue("emailInput", "");
      setEmailInput("");
    }
  };

  return (
    <div className="Home">
      <div id="top"></div>
      <CustomCursor />
      <Hero />
      <div id="sponsori" className="container p-relative">
        {/* <SponsorsBar /> */}
      </div>

      <FloatingMenu />
      <FloatingSpeakersList />

      <div id="despre">
        <div className="p-relative">
          <div className="d-md-flex justify-content-between container">
            <div className="d-flex h-100vh flex-direction-column justify-content-center text-white h2 h0-lg text-center text-md-left pl-0 pl-md-4">
              <div className="left-big-shape"></div>
              <div
                className="text-hard-glow-white animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="Show.">
                Show.
              </div>
              <div
                className="mt-4 text-hard-glow-white animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="Motivație.">
                Motivație.
              </div>
              <div
                className="mt-4 text-hard-glow-white animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="Inspirație.">
                Inspirație.
              </div>
            </div>

            <div
              className="d-flex h-100vh font-selling-points flex-direction-column justify-content-center 
            text-white align-items-center text-center">
              <div
                className="text-underline text-underline-primary underline-variant-1 animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="2 Zile.">
                2 Zile.
              </div>
              <div
                className="mt-5 text-underline text-underline-primary underline-variant-2 animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="20 de Speakeri Legendari.">
                20 de Speakeri Legendari.
              </div>
              <div
                className="mt-5 text-underline text-underline-primary underline-variant-3 animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="4 Concerte.">
                4 Concerte.
              </div>
              <div
                className="mt-5 text-underline text-underline-primary underline-variant-4 animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="4,000 de Spectatori.">
                4,000 de Spectatori.
              </div>
              <div
                className="mt-5 text-underline text-underline-primary underline-variant-1 animate__animated animate__fadeInDown reveal-on-scroll"
                glow-text="1 Show memorabil.">
                1 Show memorabil.
              </div>
            </div>
          </div>
        </div>

        <div className="p-relative">
          <div className="d-md-flex justify-content-between container">
            <div className="right-image-position-1 right-image-picture-1"></div>
            <div className="divider-1-container text-white p1 h5-lg font-semi-bold ">
              <div className="divider-1-text-container pt-0 pb-0">
                <div className="divider-1-text animate__animated animate__fadeInDown reveal-on-scroll gap-2">
                  Adunăm pe scenă personalități de renume din sport, business și showbiz pentru a-ți da doza de
                  motivație și inspirație care să îți ducă afacerea, cariera și viața la următorul nivel.
                </div>
                <div className="divider-1-text animate__animated animate__fadeInDown reveal-on-scroll">
                  Find Your Why este primul și cel mai mare show motivațional din România.
                </div>
                <div className="divider-1-text animate__animated animate__fadeInDown reveal-on-scroll">
                  Indiferent dacă plănuiești să intri în lumea businessului, să faci mai mulți bani sau să cunoști
                  oamenii potriviți, hai la Find Your Why și amintește-ți că totul e posibil.
                </div>
              </div>

              {/* <div className="divider-1-action-container">
                <div className="h3 animate__animated animate__fadeInDown reveal-on-scroll">
                  Fii primul care află când punem biletele în vânzare.
                </div>
                <div className="p1 divider-1-action-button animate__animated animate__fadeInDown reveal-on-scroll d-flex flex-direction-column align-items-center">
                  <ButtonHero
                    color="red-white"
                    is3d={false}
                    outlineOnHover={false}
                    content="Rezervă-ți locul"></ButtonHero>
                  <p className="mt-1 p1 font-semi-bold h5-md font-black-md text-white mt-2">
                    *Prinde oferta de lansare pe mail
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div id="speakeri">
        <div className="p-relative mt-7">
          <div className=" text-white small-container pl-1 pr-1">
            <div className="left-big-shape"></div>

            <div
              className="h2 h0-lg pt-4 special-word-break-1 w-90 text-soft-glow-white animate__animated animate__fadeInDown reveal-on-scroll"
              glow-text="Urcă pe scena Find Your Why">
              Urcă pe scena Find Your Why
            </div>
            {/* 
            <div
              className="p1 h4-lg w-75 font-semi-bold mt-5 p-relative animate__animated animate__fadeInDown reveal-on-scroll"
              glow-text="Artiști, legende și titani ai businessului românesc împart scena Find Your Why și îți schimbă iremediabil relația cu succesul">
              Artiști, legende și titani ai businessului românesc împart scena Find Your Why și îți schimbă iremediabil
              relația cu succesul
            </div> */}

            <div
              className="p1 h4-lg w-75 font-semi-bold mt-5 p-relative animate__animated animate__fadeInDown reveal-on-scroll"
              glow-text="Artiști legendari, influenceri și titani ai businessului împart scena Find Your Why și îți schimbă iremediabil relația cu succesul.">
              Artiști legendari, influenceri și titani ai businessului împart scena Find Your Why și îți schimbă
              iremediabil relația cu succesul.
            </div>

            <div className="d-flex justify-content-evenly flex-wrap animate__animated animate__fadeInLeft reveal-on-scroll">
              {frontPageSpeakers.map((speaker, index) => {
                return (
                  <div className="mt-5 mr-2 ml-2 object-softer-glow-red" key={"SpeakerThumbnail-" + index}>
                    <SpeakerThumbnail speaker={speaker}></SpeakerThumbnail>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="p-relative mt-4 small-container">
          <div
            className="p1 h5-lg w-75 font-semi-bold mb-2 p-relative text-center text-white"
            glow-text="și mulți alții ...">
            și mulți alții ...
          </div>

          <div className="d-flex justify-content-center align-items-center animate__animated animate__fadeIn">
            <ButtonHero
              color="red-white"
              is3d={false}
              content="Vezi toți speakerii."
              scale="0.8"
              width="500px"
              onClick={openAllSpeakersModal}></ButtonHero>
          </div>
        </div>

        <div className="p-relative mt-3">
          <div className="d-md-flex justify-content-between container">
            <div className="right-image-position-2 right-image-picture-2"></div>
            <div className="entertainers-area-container text-white p1 h5-lg font-semi-bold">
              <div className="entertainers-area-text-container">
                <div
                  className="entertainers-area-text animate__animated animate__fadeInDown reveal-on-scroll p-relative text-hard-glow-white-md"
                  glow-text="Și atmosfera va fi întreținută de">
                  Și atmosfera va fi întreținută de
                </div>
              </div>

              <div className="entertainers-area-action-container">
                <div className="entertainers-area-list d-flex justify-content-evenly flex-wrap animate__animated animate__fadeInLeft reveal-on-scroll">
                  {allEntertainers.map((speaker, index) => {
                    return (
                      <div className="mt-5 mr-2 ml-2 object-softer-glow-white" key={"EntertainerThumbnail-" + index}>
                        <EntertainerThumbnail speaker={speaker}></EntertainerThumbnail>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
        <div className="p-relative mt-4">
          <div className="d-md-flex justify-content-between container">
            <div className="right-image-position-2 right-image-picture-2"></div>
            <div className="divider-2-container text-white p1 h5-lg font-semi-bold">
              <div className="divider-2-text-container">
                <div
                  className="divider-2-text animate__animated animate__fadeInDown reveal-on-scroll p-relative text-hard-glow-white-md"
                  glow-text="Înregistrează-te gratuit cu adresa de mail și fii primul care află data lansării">
                  Înregistrează-te gratuit <wbr></wbr>cu adresa de mail și fii primul <wbr></wbr>care află data lansării
                </div>
              </div>

              <div className="divider-2-action-container">
                <div className="p1 divider-2-action-button animate__animated animate__fadeInDown reveal-on-scroll d-flex flex-direction-column align-items-center">
                  <ButtonHero color="red-white" is3d={false} content="Sunt primul care află"></ButtonHero>
                  <p className="mt-1 p1 font-semi-bold h5-md font-black-md text-white mt-2">
                    *Prinde oferta de lansare pe mail
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> 
        */}
      </div>

      <div id="show">
        <div className="p-relative mt-4 w-100 stats-divider-image pt-7 pb-7 animate__animated animate__fadeIn reveal-on-scroll">
          <div className="stats-divider d-flex container flex-wrap gap-3 align-items-start justify-content-around">
            <div className="h2 text-white text-center d-flex flex-direction-column justify-content-center">
              <div>4000+</div>
              <div>Spectatori</div>
            </div>
            <div className="h2 text-white text-center d-flex flex-direction-column justify-content-center">
              <div>{allSpeakers.length}+</div>
              <div>Speakeri</div>
            </div>
            {/* <div className="h2 text-white text-center d-flex flex-direction-column justify-content-center">
              <div>12</div>
              <div>Parteneri</div>
            </div> */}
            <div className="h2 text-white text-center d-flex flex-direction-column justify-content-center">
              <div>4</div>
              <div>Concerte</div>
            </div>
            <div className="h2 text-white text-center d-flex flex-direction-column justify-content-center">
              <div>1</div>
              <div>
                Show <br></br>incendiar
              </div>
            </div>
          </div>
        </div>

        <div className="p-relative mt-6">
          <div className="small-container">
            <div className="impact-shape-1 object-soft-glow-red object-hard-glow-red-md mt-4 animate__animated animate__fadeInLeft reveal-on-scroll">
              <div className="h1 h0-md">Show</div>
              <div className="p1 h5-md font-semi-bold pt-2 pb-3">
                Intră în atmosfera de festival și resetează-ți busola interioară pentru succes. Petrece alături de cei
                mai buni într-un show incendiar de muzică, dans, magie, multe suprize și artificii. Find Your Why este
                experiența care te scoate din zona de confort și te duce în clubul câștigătorilor.
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <div className="impact-shape-2 object-soft-glow-red object-hard-glow-red-md mt-4 animate__animated animate__fadeInRight reveal-on-scroll">
                <div className="h1 h0-md">Motivație</div>
                <div className="p1 h5-md font-semi-bold pt-2 pb-3">
                  Crezi că NU poți? Ai dreptate. Crezi că poți? Ai dreptate. Vino la Find Your Why și ia-ți doza de
                  motivație cu care să muți munții. Pleacă acasă cu un plan de acțiune care să te ducă la următorul
                  nivel.
                  <br />
                  Îți iei doza de motivație pentru ca să îți transformi întreaga viață.
                </div>
              </div>
            </div>

            <div className="impact-shape-3 object-soft-glow-red object-hard-glow-red-md mt-4 animate__animated animate__fadeInLeft reveal-on-scroll">
              <div className="h1 h0-md">Networking</div>
              <div className="p1 h5-md font-semi-bold pt-2 pb-3">
                Când ești înconjurat de cei mai buni, ai toate șansele să devii următorul. Problema? Și viceversa e
                valabilă. Cunoaște liderii, artiștii, oamenii de afaceri, specialiștii și milionarii care fac lucrurile
                să se întâmple în România.
              </div>
            </div>
          </div>
        </div>

        <div className="p-relative mt-7">
          <div className="d-flex justify-content-center container animate__animated animate__fadeInDown reveal-on-scroll">
            <div className="h3 w-100 h1-md text-white text-center wrapped-section-tile-1">
              De ce să vii la un show motivațional unic
            </div>
          </div>

          <div className="d-flex justify-content-start mt-6 container">
            <div className="animate__animated animate__fadeInLeft reveal-on-scroll pl-2 pr-2">
              <div className="h3 h2-md text-primary text-left text-underline text-underline-white underline-variant-5 object-soft-glow-white">
                Lasă-te inspirat de cei mai buni
              </div>
              <div className="mt-3 text-white">
                <p className="">Fără bulls*it și vorbe de umplutură. Află de la cei mai buni:</p>
                <p className="bullet-point-left">Cum au trecut peste eșec</p>
                <p className="bullet-point-left">Unde și-au găsit motivația</p>
                <p className="bullet-point-left">Care este secretul milionarilor români</p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2 container">
            <div className="animate__animated animate__fadeInRight reveal-on-scroll pl-2 pr-2">
              <div className="h3 h2-md text-primary text-right text-underline text-underline-white underline-variant-6 object-soft-glow-white">
                Și schimbă-ți povestea
              </div>
              <div className="mt-3 text-white text-left pl-0 pl-md-3">
                <p className="">La Find Your Why găsești motivația și inspirația să</p>
                <p className="bullet-point-left">Devii din victimă câștigător</p>
                <p className="bullet-point-left">Preiei controlul propriei vieți</p>
                <p className="bullet-point-left">Îți duci cariera, afacerea & viața la următorul nivel</p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-start mt-2 container">
            <div className="animate__animated animate__fadeInLeft reveal-on-scroll pl-2 pr-2">
              <div className="h3 h2-md text-primary text-left text-underline text-underline-white underline-variant-10 object-soft-glow-white">
                Inspirație si networking
                <br />
                cu speakeri de top
              </div>
              <div className="mt-3 text-white">
                <p className=""></p>
                <p className="bullet-point-left">Ascultă povești inspiraționale de la lideri de succes</p>
                <p className="bullet-point-left">
                  Afli de la cei mai influenți oameni din domeniul lor cum poți ajunge și tu cel mai bun
                </p>
                <p className="bullet-point-left">
                  Oportunitatea de a interacționa și a construi relații cu cei mai buni: oameni de business,
                  <br />
                  influenceri, artiști, sportivi și lideri în domeniul lor, persoane pe care ai visat să le întâlnești.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-2 container">
            <div className="animate__animated animate__fadeInRight reveal-on-scroll pl-2 pr-2">
              <div className="h3 h2-md text-primary text-right text-underline text-underline-white underline-variant-11 object-soft-glow-white">
                Experiență Unică
              </div>
              <div className="mt-3 text-white text-left pl-0 pl-md-3">
                <p className="bullet-point-left">
                  Un amestec vibrant de show-uri live, muzică și dans, show de magie
                  <br />
                  și multe surprize pentru o experiență memorabilă.
                </p>
                <p className="bullet-point-left">
                  Experimentează un boost de energie și motivație <br />
                  pentru a-ți urma pasiunile
                </p>
                <p className="bullet-point-left">Te distrezi, pleci motivat și inspirat</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="p-relative mt-6">
          <div className="cta-divider-1 d-flex flex-direction-column align-items-center text-center animate__animated animate__zoomInDown reveal-on-scroll">
            <div className="h2 h1-md">Prinde cele mai bune locuri din sală</div>
            <p className="mt-3">
              Înregistrează-te cu adresa de mail și fii primul care află care sunt cele mai bune oferte la pachetele
              Find Your Why:
            </p>

            <div className="subscribe-email-form mt-3 pl-2 pl-md-0 pr-2 pr-md-0">
              <form onSubmit={handleSubmit(registerToNewsletter)} className="">
                <label className="" htmlFor="emailInput">
                  <div className={`text-left ${errors?.emailInput ? "custom-input-text-error" : ""}`}>
                    <div className="w-100 text-lt-gray">{`E-mail`}</div>
                  </div>
                  <input
                    className="custom-input w-100 mt-1"
                    id="emailInput"
                    value={emailInput}
                    placeholder="E-mail"
                    {...register("emailInput", { validate: true })}
                    onChange={(e) => {
                      setEmailInput(e.target.value);
                      setValue("emailInput", e.target.value, { shouldValidate: true });
                    }}></input>
                </label>

                <div className={`${isRegisteringToNewsletter ? "disabled" : ""} mt-3 w-100`}>
                  <ButtonHero
                    onClick={() => registerToNewsletter({ emailInput: emailInput })}
                    color="black-white"
                    is3d={false}
                    content={`${isRegisteringToNewsletter ? "Se trimite ..." : "Rezervă-ți locul"}`}
                    type="submit"
                    glow="glow-hard"></ButtonHero>
                </div>
                <div className="text-sm mt-2">
                  {`* Apăsând pe buton îți dai consimțământul că esți de acord cu `}{" "}
                  <a target="_blank" rel="noopener noreferrer" href="/politica-de-confidentialitate">
                    Politica de Confidențialitate
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div> */}
      </div>

      <div>
        <div className="p-relative mt-7">
          <div className="small-container">
            <div className="d-flex flex-direction-column align-items-center text-white text-center animate__animated animate__fadeInDown reveal-on-scroll">
              <div className="h2 h1-md">Cea mai bună decizie? Investiția în tine.</div>
              <p className="mt-3">
                {/* {`Oferta ${promotionNames[activePriceTimeline]} este valabilă până pe ${
                  salesDeadline
                    .toLocaleDateString("ro-RO", { day: "numeric", month: "long", year: "numeric" })
                    .charAt(0)
                    .toUpperCase() +
                  salesDeadline.toLocaleDateString("ro-RO", { day: "numeric", month: "long", year: "numeric" }).slice(1)
                }, ora 23:59. `} */}

                {`Locurile sunt limitate, nu pierde șansa
                să economisești până la -80%. Rezervă-ți locul acum la cel mai bun preț.`}
              </p>
            </div>
          </div>
        </div>
        <div id="bilete" className="p-relative mt-5">
          <Tickets />
        </div>

        <div className="p-relative mt-5">
          <div className="container">
            <div className="d-flex flex-direction-column align-items-center text-white text-center animate__animated animate__fadeInDown reveal-on-scroll">
              <div className="h2 h1-md">Ce spun speakerii</div>
            </div>

            {[...visibleFrontPageInterviews, ...(showMoreInterviews?hiddenFrontPageInterviews:[])].map((speakerInterview, index) => {
              return (
                <div
                  className={`quote-container mt-5 animate__animated ${
                    index % 2 === 0 ? "animate__fadeInLeft" : "animate__fadeInRight"
                  } reveal-on-scroll`}>
                  <div className={`quote-wrapper ${index % 2 === 0 ? "quote-left" : "quote-right"}`}>
                    <div className="speaker-quote">
                      <div className="quote-media-container">
                        <div className="quote-video">
                          {speakerInterview?.shortVideoInterviewUrl?.length > 0 ? (
                            <iframe
                              width="100%"
                              height="100%"
                              className="autoplay-on-scroll"
                              src={`${speakerInterview.shortVideoInterviewUrl}?autoplay=0&rel=0&${
                                isMobileDevice() ? "mute=0" : "mute=1"
                              }&enablejsapi=1;controls=0`}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen></iframe>
                          ) : (
                            <>
                              {/* <img className="quote-image" src={speakerInterview.photo} alt={speakerInterview.name}></img> */}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="content">
                        <div className="h3 text-white">{speakerInterview.name}</div>
                        <div className="p1 text-white mt-2">{speakerInterview.quote}</div>
                      </div>
                    </div>
                    <div className="speaker-quote-glow-red"></div>
                  </div>
                </div>
              );
            })}

            {showMoreInterviews ? <></> : <></>}
            <div className="d-flex justify-content-center align-items-center mt-4">
              <ButtonHero
                color="red-white"
                is3d={false}
                content={showMoreInterviews ? "Ascunde interviurile" : "Vezi toate interviurile"}
                scale="0.8"
                width="500px"
                onClick={() => setShowMoreInterviews((s) => !s)}></ButtonHero>
            </div>
          </div>
        </div>
      </div>

      <div id="oferte">
        <div className="p-relative mt-7">
          <div className="cta-divider-2 animate__animated animate__zoomInDown reveal-on-scroll">
            <div className="container">
              <div className="cta-divider-2-content">
                <div className="h2">Prinde oferta de grup</div>
                <div className="mt-3 h5 font-semi-bold">Team building la Find Your Why? Da, vă rog!</div>
                <div className="mt-3 h2">Motivație & educație 100% practică pentru echipa ta</div>
                <div className="mt-3 h5 font-semi-bold">
                  Adu-ți echipa la Find Your Why și beneficiezi de extra 20% discount pentru minim 7 pachete
                  achiziționate.
                </div>

                <div className="mt-3 d-flex justify-content-center pr-0 pr-xl-5">
                  <ButtonHero
                    color="white-black"
                    is3d={false}
                    content="Cere oferta de grup"
                    onClick={() => openGroupOfferModal()}
                    scale="0.8"
                    width="500px"></ButtonHero>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-relative mt-5">
          <div className="small-container">
            <div className="d-flex flex-direction-column align-items-center text-white text-center animate__animated animate__fadeInDown reveal-on-scroll">
              <div
                className="h1 text-hard-glow-white"
                glow-text="Find Your Why, un show care creează oportunități pentru...">
                Find Your Why, un show care creează oportunități pentru...
              </div>
              <div
                className="h5 mt-4 text-hard-glow-white"
                glow-text="Înconjoară-te de milionari, artiști de renume, oameni de afaceri și influenceri de succes">
                Înconjoară-te de milionari, artiști de renume, oameni de afaceri și influenceri de succes
              </div>
              <div
                className="h5 text-hard-glow-white"
                glow-text="- și e o chestiune de timp până când devii unul dintre ei.">
                - și e o chestiune de timp până când devii unul dintre ei.
              </div>
            </div>

            <div className="d-flex mt-3 justify-conten-center align-items-center flex-direction-column pl-1 pr-1 pl-lg-2 pr-lg-2 pl-xl-4 pr-xl-4">
              {stakeholdersText.map((stakeholder, index) => {
                return (
                  <div className="mt-3 w-100" key={"stakeholder-item-" + index}>
                    <ExpandablePolygon {...stakeholder}></ExpandablePolygon>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="p-relative mt-6">
          <div className="cta-divider-3 d-flex flex-direction-column align-items-center text-center animate__animated animate__zoomInDown reveal-on-scroll pl-0 pr-0 pl-md-2 pr-md-2">
            <div className="h2 h1-md">Ești gata de o experiență transformatoare?</div>
            <p className="mt-3">{`Fii primul care prinde oferta ${promotionNames[activePriceTimeline]} cu -80% reducere:`}</p>

            <ButtonHero
              color="white-black"
              is3d={false}
              content="Rezervă-ți locul"
              width="600px"
              link="/#bilete"></ButtonHero>
            <p className="mt-2 mb-4">Locurile limitate se epuizează pe principiul primul venit, primul servit</p>
          </div>
        </div>
      </div>

      <div id="locatie">
        <div className="p-relative mt-6">
          <div className="small-container d-flex flex-direction-column align-items-center animate__animated animate__fadeInDown reveal-on-scroll">
            {/* <div className="animate__animated animate__fadeInDown reveal-on-scroll"> */}
            <div className="h3 w-100 h1-md text-center wrapped-section-tile-2 object-soft-glow-white">
              Locația show-ului
            </div>
            {/* </div> */}
            <div className="mt-5 h5 font-semi-bold text-white text-center">Sala palatului.</div>
            <div className="mt-25 h5 font-semi-bold text-white text-center">
              Adresă: Strada Ion Câmpineanu 28, București
            </div>
            <div className="mt-4"></div>
            <ButtonHero
              color="red-white"
              is3d={false}
              content="Vezi pe Google Maps"
              width="500px"
              target="_blank"
              rel="noopener noreferrer"
              link="https://www.google.com/maps/place/The+Palace+Hall/@44.4391567,26.092479,17z/data=!3m1!4b1!4m6!3m5!1s0x40b1ff453d5735db:0x2c539f3aae8a4115!8m2!3d44.4391529!4d26.0950539!16zL20vMDlrM3ds?entry=ttu"></ButtonHero>
          </div>
        </div>

        <div className="p-relative mt-5 small-container">
          <GoogleMapLocation />
        </div>

        <div className="p-relative mt-7">
          <div className="small-container d-flex flex-direction-column align-items-center animate__animated animate__fadeInDown reveal-on-scroll">
            <div className="h3 w-100 h1-md text-center wrapped-section-tile-3 object-hard-glow-red">Nu rata nimic</div>
            {/* </div> */}
            <div
              className="mt-5 h5 font-semi-bold text-white text-center text-hard-glow-white"
              glow-text="Fii la curent cu cele mai bune oferte, schimbările de preț,">
              Fii la curent cu cele mai bune oferte, schimbările de preț,
            </div>
            <div
              className="mt-25 h5 font-semi-bold text-white text-center text-hard-glow-white"
              glow-text="ultimele reduceri și ultimii speakeri confirmați ai show-ului.">
              ultimele reduceri și ultimii speakeri confirmați ai show-ului.
            </div>

            <div className="subscribe-email-form mt-4 pl-2 pl-md-0 pr-2 pr-md-0">
              <form onSubmit={handleSubmit(registerToNewsletter)} className="">
                <label className="" htmlFor="emailInput">
                  <div className={`text-left text-white ${errors?.emailInput ? "custom-input-text-error" : ""}`}>
                    <div className="w-100 text-lt-gray">{`E-mail`}</div>
                  </div>
                  <input
                    className="custom-input w-100 mt-1 object-soft-glow-white"
                    id="emailInput"
                    value={emailInput}
                    placeholder="E-mail"
                    {...register("emailInput", { validate: true })}
                    onChange={(e) => {
                      setEmailInput(e.target.value);
                      setValue("emailInput", e.target.value, { shouldValidate: true });
                    }}></input>
                </label>

                <div className={`${isRegisteringToNewsletter ? "disabled" : ""} mt-3 w-100 object-hard-glow-red`}>
                  <ButtonHero
                    onClick={() => registerToNewsletter({ emailInput: emailInput })}
                    color="red-white"
                    is3d={false}
                    content={`${isRegisteringToNewsletter ? "Se trimite ..." : "Anunță-mă pe mail"}`}
                    type="submit"
                    glow="glow-hard"></ButtonHero>
                </div>
                <div className="text-sm text-white mt-2">
                  {`* Apăsând pe buton îți dai consimțământul că esți de acord cu `}{" "}
                  <a target="_blank" rel="noopener noreferrer" href="/politica-de-confidentialitate">
                    Politica de Confidențialitate
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="p-relative mt-7">
        <Footer />
      </div>

      <AnimationRevealer />
      <GlowRevealer />
    </div>
  );
}

export default Home;
