import "./expandable-polygon.scss";

function ExpandablePolygon({title, content, photo, side, color}) {
  return (
    <div className={`expandable-polygon-container ${side}-side`}>
      <div className={`expandable-polygon-wrapper
      animate__animated reveal-on-scroll ${side==="left"?"animate__fadeInLeft":"animate__fadeInRight"} 
      ${color==="red"?"polygon-red":"polygon-white"}
      object-${color==="red"?"hard":"soft"}-glow-${color}`}>

        <div className="polygon-text">
          <div className="h4">{title}</div>
          <p>{content}</p>
        </div>

        <div className={`expandable-polygon expandable-polygon-${side}`}>
          <img src={photo} alt={title} className="image-inside" />
        </div>
      </div>
    </div>
  );
}

export default ExpandablePolygon;
