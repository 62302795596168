import "./progress-bar.scss";

function ProgressBar({percentage}) {

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div className="filled-bar" style={{width: `calc(${percentage}% + 1rem)`}}>
        </div>
        <div className={`label ${parseFloat(percentage)>50?'left text-white':'right text-primary'}`}>{percentage} % Sold out</div>
      </div>
    </div>
  );
}
export default ProgressBar;
