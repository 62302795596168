import NavMenuBar from "./../../components/nav-menu-bar/nav-menu-bar";
import { Outlet, useLocation } from "react-router-dom";
import "./layout.scss";
import ModalContainer from "../../utils/modal/modalContainer";
import { ModalProvider } from "../../utils/modal/modalContext";
import SalesTimer from "../../components/sales-timer/sales-timer";
import { getFirestore } from "firebase/firestore";
import { AnalyticsProvider, FirestoreProvider, useFirebaseApp } from "reactfire";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import ButtonHero from "../../components/button-hero/button-hero";
import { getAnalytics, setCurrentScreen, logEvent } from "firebase/analytics";
import { useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';
// import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga4';
import { isAppleDevice } from "../../utils/utils";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

// React pixel _ attempt 1
// 
// ReactPixel.init(process.env.REACT_APP_PUBLIC_META_PIXEL_ID, null, options);
// ReactPixel.revokeConsent();
// console.log("ReactPixel.init")

ReactGA.initialize(process.env.REACT_APP_PUBLIC_GOOGLE_ANALYTICS_4_ID);
console.log("ReactGA.init")

// Google tag manager _ attempt 1
// 
// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_PUBLIC_GOOGLE_TAG_MANAGER
// }
// TagManager.initialize(tagManagerArgs)
// console.log("tagManagerArgs.init")


function Layout() {
  const firestoreInstance = getFirestore(useFirebaseApp());
  const analyticsInstance = getAnalytics(useFirebaseApp());
  const location = useLocation();

  useEffect(()=>{   
    // Firebase Analytics tracking
    // 
    setCurrentScreen(analyticsInstance, location?.pathname);
    logEvent(analyticsInstance, 'screen_view');

    // Google Analytics 4 tracking
    // 
    ReactGA.send({ hitType: "pageview", page: location.pathname });

    // Meta tracking _ attempt 1
    // 
    // ReactPixel.pageView(); // For tracking page view
    // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
    // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
    // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
    // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.

    // Meta tracking _ attempt 2
    // 
    if (window?.fbq) {
      window.fbq('track', 'PageView');
    }

    // Google ADS AdWords tracking
    // 
    if (window.gtag) {
      window.gtag('config', process.env.REACT_APP_PUBLIC_GOOGLE_ADS_TAG);
      window.gtag('event', 'page_view', {'send_to': `${process.env.REACT_APP_PUBLIC_GOOGLE_ADS_TAG}/${location?.pathname}`});
    }
  },[analyticsInstance, location]);


  return (
    <div className={`main-layout ${isAppleDevice()?"apple-device":"non-apple-device"}`}>
      <FirestoreProvider sdk={firestoreInstance}>
        <AnalyticsProvider sdk={analyticsInstance}>
          <ModalProvider>
            <NavMenuBar />
            <SalesTimer />
            <Outlet />

            <CookieConsent
              enableDeclineButton
              flipButtons
              location="bottom"
              buttonText={
                <div className="cookie-confirm-button">
                  <ButtonHero color="white-black" scale={"0.6"} is3d={false} content="Accept cookie-urile"></ButtonHero>
                </div>
              }
              declineButtonText={
                <div className="mb-2 cookie-decline-button">
                Nu accept cookie-urile
              </div>
              }
              cookieName="myAwesomeCookieName2"
              style={{ background: "rgb(193 32 38 / 60%)", backdropFilter: "blur(8px)", boxShadow: "0px 20px 50px 15px #1b1b1b", zIndex: "9999999"}}
              buttonStyle={{ all: "unset" }}
              declineButtonStyle={{ all: "unset", cursor: "pointer" }}
              expires={150}
              onAccept={() => ReactPixel.grantConsent()}
              onDecline={() => ReactPixel.revokeConsent()}
              >
              Acest site folosește cookie-uri pentru a îmbunătăți experiența.{" "}
            </CookieConsent>

            <ModalContainer />
          </ModalProvider>
        </AnalyticsProvider>
      </FirestoreProvider>
      <ToastContainer />
    </div>
  );
}

export default Layout;
