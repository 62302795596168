import {useEffect, useRef} from "react";
import {isMobileDevice, isPerformanceFasterThan} from "../../utils/utils";
const ioConfiguration = {
  /**
   * This rootMargin creates a horizontal line vertically centered
   * that will help trigger an intersection at that the very point.
   */
  rootMargin: "0% 0% 0% 0%",

  /**
   * This is the default so you could remove it.
   * I just wanted to leave it here to make it more explicit
   * as this threshold is the only one that works with the above
   * rootMargin
   */
  threshold: 0,
};

const afterGlowClasses = [
  "text-soft-glow-white",
  "text-hard-glow-white",
  "text-glow-red",
  "text-soft-glow-red",
  "text-hard-glow-red",

  "text-soft-glow-white-xs",
  "text-hard-glow-white-xs",
  "text-glow-red-xs",

  "text-soft-glow-white-sm",
  "text-hard-glow-white-sm",
  "text-glow-red-sm",

  "text-soft-glow-white-md",
  "text-hard-glow-white-md",
  "text-glow-red-md",

  "text-soft-glow-white-lg",
  "text-hard-glow-white-lg",
  "text-glow-red-lg",

  "text-soft-glow-white-xl",
  "text-hard-glow-white-xl",
  "text-glow-red-xl",

  "text-soft-glow-white-xxl",
  "text-hard-glow-white-xxl",
  "text-glow-red-xxl",
];

const filterGlowClasses = [
  "speaker-quote-glow-red",

  "object-soft-glow-white",
  "object-hard-glow-white",
  "object-softer-glow-red",
  "object-soft-glow-red",
  "object-hard-glow-red",
  "clone-glow",

  "object-soft-glow-white-xs",
  "object-hard-glow-white-xs",
  "object-soft-glow-red-xs",
  "object-hard-glow-red-xs",
  "clone-glow-xs",

  "object-soft-glow-white-sm",
  "object-hard-glow-white-sm",
  "object-soft-glow-red-sm",
  "object-hard-glow-red-sm",
  "clone-glow-sm",

  "object-soft-glow-white-md",
  "object-hard-glow-white-md",
  "object-soft-glow-red-md",
  "object-hard-glow-red-md",
  "clone-glow-md",

  "object-soft-glow-white-lg",
  "object-hard-glow-white-lg",
  "object-soft-glow-red-lg",
  "object-hard-glow-red-lg",
  "clone-glow-lg",

  "object-soft-glow-white-xl",
  "object-hard-glow-white-xl",
  "object-soft-glow-red-xl",
  "object-hard-glow-red-xl",
  "clone-glow-xl",

  "object-soft-glow-white-xxl",
  "object-hard-glow-white-xxl",
  "object-soft-glow-red-xxl",
  "object-hard-glow-red-xxl",
  "clone-glow-xxl",
];
// Register IntersectionObserver for after glows
const afterGlowRevealerIo = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      // if (entry.target.classList?.contains("reveal-on-scroll")) {
      //   entry.target.style.willChange = "opacity, transform";
      // }

      // Adding a little delay so the browser gets to do the will-change optimizations
      // and so we catch the whole animation
      // setTimeout(() => {
      // Observation target is inside viewport
      entry.target.classList.remove("remove-after-glows");
      // }, 1);
    } else {
      // Also add the class back if you want the element to reveal
      // itself next times it comes into viewport

      entry.target.classList.add("remove-after-glows");
      // if (entry.target.style.willChange !== "auto") {
      //   entry.target.style.willChange = "auto";
      // }
    }
  });
}, ioConfiguration);

// Register IntersectionObserver for filter glows
const filterGlowRevealerIo = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.intersectionRatio > 0) {
      // if (entry.target.classList?.contains("reveal-on-scroll")) {
      //   entry.target.style.willChange = "opacity, transform";
      // }

      // Adding a little delay so the browser gets to do the will-change optimizations
      // and so we catch the whole animation
      // setTimeout(() => {
      // Observation target is inside viewport
      entry.target.classList.remove("remove-filter-glows");
      // }, 1);
    } else {
      // Also add the class back if you want the element to reveal
      // itself next times it comes into viewport

      entry.target.classList.add("remove-filter-glows");
      // if (entry.target.style.willChange !== "auto") {
      //   entry.target.style.willChange = "auto";
      // }
    }
  });
}, ioConfiguration);

function GlowRevealer() {
  const performanceObserver = useRef();

  useEffect(() => {
    const removeAllGlows = () => {
      const afterGlowsElements = afterGlowClasses.reduce(
        (prev, curr) => [...prev, ...document.getElementsByClassName(curr)],
        []
      );
      console.log("afterGlowsElements", afterGlowsElements);

      afterGlowsElements.forEach((el) => {
        el.classList.add("remove-after-glows");
        afterGlowRevealerIo.unobserve(el);
      });

      const filterGlowsElements = filterGlowClasses.reduce(
        (prev, curr) => [...prev, ...document.getElementsByClassName(curr)],
        []
      );
      console.log("filterGlowsElements", filterGlowsElements);
      filterGlowsElements.forEach((el) => {
        el.classList.add("remove-filter-glows");
        filterGlowRevealerIo.unobserve(el);
      });
    };

    removeAllGlows();
    console.log("removing all glows");
    
    async function conditionallyRenderGlows() {
      try {
        const virtualizeGlows = () => {
          setTimeout(() => {
            const afterGlowsElements = afterGlowClasses.reduce(
              (prev, curr) => [...prev, ...document.getElementsByClassName(curr)],
              []
            );
            // console.log("afterGlowsElements", afterGlowsElements);
            afterGlowsElements.forEach((el) => {
              el.classList.add("remove-after-glows");
              afterGlowRevealerIo.observe(el);
            });

            const filterGlowsElements = filterGlowClasses.reduce(
              (prev, curr) => [...prev, ...document.getElementsByClassName(curr)],
              []
            );
            // console.log("filterGlowsElements", filterGlowsElements);

            filterGlowsElements.forEach((el) => {
              el.classList.add("remove-filter-glows");
              filterGlowRevealerIo.observe(el);
            });
          }, 500);
        };

        if ((await isPerformanceFasterThan(90, performanceObserver)) && !isMobileDevice()) {
          console.log("isPerformanceFasterThan(90) - glow on", true);
          virtualizeGlows();
          performanceObserver.current?.disconnect();
        } else {
          console.log("isPerformanceFasterThan(90) - glow off", false);
          // Remove animations
          // console.log("removing animations");
          // const animatedElements = [...document.getElementsByClassName("animate__animated")];
          // animatedElements.forEach((el) => {
          //   el.classList.remove("animate__animated");
          // });
        }
      } catch (err) {
        console.log(err);
      }
    }
    conditionallyRenderGlows();

    return () => {
      performanceObserver.current?.disconnect();

      removeAllGlows();

      afterGlowRevealerIo.disconnect();
      filterGlowRevealerIo.disconnect();
    };
  }, []);

  return <div></div>;
}

export default GlowRevealer;
