import React, { useEffect, useRef, useState } from 'react';
import './google-map.scss';
import GoogleMapMarker from '../google-map-marker/google-map-marker';
import GoogleMap from 'google-maps-react-markers';

function GoogleMapLocation() {
  const defaultProps = {
    center: {
      lat: 44.4391567,
      lng: 26.0854671
    },
    
    zoom: 15
  };

  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)

  useEffect(()=>{
    // ToDo: This should be done automatically after inserting the api keys
    onGoogleApiLoaded(mapRef);
  }, [])

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
  }

  const onMarkerClick = (e, { markerId, lat, lng }) => {
    console.log('This is ->', markerId)

    // inside the map instance you can call any google maps method
    mapRef.current.setCenter({ lat, lng })
    // rif. https://developers.google.com/maps/documentation/javascript/reference?hl=it
  }

  return (
    <div className="map-container">
      <div className="map-overlay">
        <img width="100%" src={require("./../../assets/images/sala-palatului.png")} alt="Sala Palatului"></img>
      </div>
      {
        mapReady &&
        <GoogleMap
          ref={mapRef}
          apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={onGoogleApiLoaded}
        >
          <GoogleMapMarker
            lat={44.4388922}
            lng={26.0938899}
            onClick={onMarkerClick}
          />
        </GoogleMap>
      }
    </div>
  );
}

export default GoogleMapLocation;

