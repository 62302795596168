import { useEffect, useRef, useState } from "react";
import "./floating-speakers-list.scss";
import { useDebouncedCallback } from "use-debounce";
import { floatingSideBulletSpeakers } from "../../utils/constants";

function FloatingSpeakersList() {
  const scrollableContainer = useRef();
  const heightOfOneItem = 175;
  const idealScrollAmount = window.innerHeight * 0.7;
  const [shouldShowUpArrow, setShouldShowUpArrow] = useState(false);

  const scrollDown = () => {
    const currentScroll = scrollableContainer.current.scrollTop;
    const remainingScroll = floatingSideBulletSpeakers.length * heightOfOneItem - currentScroll;
    scrollableContainer.current.scrollBy({
      top: remainingScroll > idealScrollAmount ? idealScrollAmount : remainingScroll,
      left: 0,
      behavior: "smooth",
    });
  };

  const scrollUp = () => {
    const currentScroll = scrollableContainer.current.scrollTop;
    scrollableContainer.current.scrollBy({
      top: -(currentScroll > idealScrollAmount ? idealScrollAmount : currentScroll),
      left: 0,
      behavior: "smooth",
    });
  };

  const debouncedScrollEvent = useDebouncedCallback((value) => {
    if (value > heightOfOneItem) {
      setShouldShowUpArrow(true);
    } else {
      setShouldShowUpArrow(false);
    }
  }, 200);

  return (
    <div className="floating-speakers-list-container">
      <div
        className="speakers-list-container"
        ref={scrollableContainer}
        onScroll={(e) => debouncedScrollEvent(e?.target?.scrollTop)}>
        {shouldShowUpArrow ? (
          <>
            <div className="scroll-speakers-up mt-2" onClick={scrollUp}></div>
          </>
        ) : (
          <></>
        )}
        {floatingSideBulletSpeakers.map((speaker, index) => {
          return (
            <div className="mt-1 floating-speaker-container" key={"SpeakerThumbnail-" + index}>
              <a href={`/ce-spun-speakerii/${speaker.url}`}>
                <div className="floating-speaker">
                  <img
                    src={speaker.photo}
                    alt={speaker.name}
                    className="floating-speaker-image"
                    width="100%"
                    height="auto"
                  />
                  <div className="floating-speaker-description pt-2">
                    <div className="p2">{speaker.name}</div>
                    {speaker?.days?.length > 0 ? (
                      <div className="p2 font-semi-bold">{speaker.days.toString()}</div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </a>
            </div>
          );
        })}
        <div className="scroll-speakers-down mt-2" onClick={scrollDown}></div>
      </div>
    </div>
  );
}

export default FloatingSpeakersList;
