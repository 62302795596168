import "./package-offer.scss";
import ButtonHero from "../../components/button-hero/button-hero";
import bestValueRedRibbon from "./../../assets/images/ribbons/best-value-red.svg";
import bestDayRedRibbon from "./../../assets/images/ribbons/best-day-red.svg";
import bestValueWhiteRibbon from "./../../assets/images/ribbons/best-value-white.svg";
import bestDayWhiteRibbon from "./../../assets/images/ribbons/best-day-white.svg";
import ProgressBar from "../progress-bar/progress-bar";

function PackageOffer({ offer, activePriceTimeline, onClick = () => {} }) {
  return (
    <div className={`package-offer-container package-${offer.color} ${offer.isMainPackage ? "main-package" : ""}`}>
      {offer.isMainPackage ? <div className={`main-glow`}></div> : ""}

      <div className={`package-offer ${offer.isMainPackage ? "" : "package-glow"}`}>
        <div className={`h2 text-center package-title pl-2 pr-2`}>{offer.title}</div>

        <div className="mt-3 w-100">
          <ProgressBar percentage={offer.soldOutPercentage}></ProgressBar>
        </div>

        <div className="mt-3">
          {offer.points.map((point, index) => {
            return (
              <div className="mt-1" key={"offer.points-" + index}>
                <div className="bullet-point-left">{point}</div>
              </div>
            );
          })}
          {offer.missedOutPoints.map((point, index) => {
            return (
              <div className="mt-1" key={"offer.missedOutPoints-" + index}>
                <div className="bullet-point-left missed-out-point">{point}</div>
              </div>
            );
          })}
        </div>
        <div className="mt-3">
          <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center">
            {offer.isOnSale ? (
              <>
                <div>
                  <div className="">
                    <div className="h3 h2-md price transparent-text strikeThrough">{`${offer.prices[activePriceTimeline].price} ${offer.currency}`}</div>
                    {offer.prices[activePriceTimeline]?.discount ? (
                      <>
                        <div className="p1 mt-1 text-center">{`Reducere: ${offer.prices[activePriceTimeline].discount}`}</div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="h3 h2-md price mt-1 font-black text-underline text-underline-black underline-variant-9">
                  <div className="">{`${offer.prices[activePriceTimeline].salePrice} ${offer.currency}`}</div>
                </div>
              </>
            ) : (
              <>
                <div className="h3 h2-md price mt-1 font-black text-underline text-underline-black underline-variant-9">
                  <div className="">{`${offer.prices[activePriceTimeline].price} ${offer.currency}`}</div>
                </div>
              </>
            )}
          </div>
        </div>

        {/* <div className="mt-3 w-100">
          <ButtonHero
            onClick={() => onClick()}
            color={`${offer.color === "red" ? "white-black" : "red-black"}`}
            is3d={true}
            isPulsing={true}
            content="Rezerv acum"
            scale="0.8"></ButtonHero>
        </div> */}

        <div className="pt-2 d-flex flex-direction-column align-items-center">
          <div className="">
            {offer.prices.map((priceType, index) => {
              return (
                <div
                  className={`mt-1 d-flex align-items-center p1 font-black
                   ${activePriceTimeline === index ? "" : "transparent-text"}
                   `}
                  key={"offer.prices-" + index}>
                  <div className="mr-2 d-flex align-items-center gap-1">
                    <div className={`${index === 1 && activePriceTimeline !== index ? "blurred-text" : ""}`}>
                      {priceType.salePrice || priceType.price}
                    </div>
                    <div>{offer.currency}</div>
                  </div>
                  <div>{priceType.type}</div>
                  {/* {
                    priceType?.discount ? <>
                    <div className="ml-1">{` - Reducere: ${priceType.discount}`}</div>
                    </>:<></>
                  } */}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="package-offer-tail package-glow">
        {/* <div className="pt-2 d-flex flex-direction-column align-items-center">
          <div className="">
            {offer.prices.map((priceType, index) => {
              return (
                <div
                  className={`mt-1 d-flex align-items-center p1 font-black
                   ${activePriceTimeline === index ? "" : "transparent-text"}
                   `}
                   
                  key={"offer.prices-" + index}>
                  <div className="mr-2 d-flex align-items-center gap-1">
                    <div className={`${index === 1 && activePriceTimeline !== index ? "blurred-text" : ""}`}>
                    {priceType.salePrice || priceType.price}
                    </div>
                    <div>
                    {offer.currency}
                    </div>

                  </div>
                  <div>{priceType.type}</div>
                </div>
              );
            })}
          </div>
        </div> */}


        <div className="w-100">
          <ButtonHero
            onClick={() => onClick()}
            color={`${offer.color === "red" ? "white-black" : "red-black"}`}
            is3d={true}
            isPulsing={true}
            content="Rezerv acum"
            scale="0.65"></ButtonHero>
        </div>
        
        {/* <div className="pb-3">
          <div className="text-center p2">de la 139 lei cu cod de 30% OFF </div>
          <div className="text-center p2">Folosește codul pe iabilet.ro </div>
          <div className="text-center p2">după ce selectezi locurile în sală!</div>
        </div> */}

      </div>

      <div className="ribbon">
        {(() => {
          switch (offer.ribbonType) {
            case "best-day":
              if (offer.color === "red") return <img src={bestDayWhiteRibbon} alt="Best day"></img>;
              else return <img src={bestDayRedRibbon} alt="Best day"></img>;
            case "best-value":
              if (offer.color === "red") return <img src={bestValueWhiteRibbon} alt="Best value"></img>;
              else return <img src={bestValueRedRibbon} alt="Best value"></img>;
            default:
              return <></>;
          }
        })()}
      </div>
    </div>
  );
}

export default PackageOffer;
