import "./legal.scss";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import Footer from "../../components/footer/footer";

function PoliticaDeCookies() {
  return (
    <div className="Home">
      <CustomCursor />

      <div className="p-relative mt-7 text-white font-normal">
        <div className="container">
          <h1 className="h0 text-hard-glow-white" glow-text="Politica de Cookies">
            Politica de Cookies
          </h1>
          <h5
            className="h5 text-hard-glow-white"
            glow-text="Politica de cookies a website-ului 'https://findyourwhy.ro/'">
            Politica de cookies a website-ului 'https://findyourwhy.ro/'
          </h5>
          <div className="mt-4">{`Acest site folosește cookie-uri.`}</div>
          <div className="mt-2">{`
            Politica de cookie-uri explică ce sunt cookie-urile și cum le folosim pe site-ul nostru.
            Trebuie să citești aceasta politică pentru a înțelege ce tip de cookie-uri folosim, informațiile
            pe care le colectăm cu ajutorul modulelor cookie și modul în care sunt utilizate aceste
            informații.
          `}</div>
          <div className="mt-2">{`
Cookie-urile sunt fișiere de mici dimensiuni sau părți dintr-un fișier salvate pe
calculatorul/ terminalul mobil al unui utilizator de internet, formate din litere și numere și
stocate de către browserul utilizat pentru o mai bună experiență de navigare pe internet.
Aceste fișiere permit site-urilor web să rețină și să păstreze informații utile despre
preferințele utilizatorului și pot conține informații ce au caracter personal precum, un
istoric al paginilor vizitate, codul de identificare a utilizatorului, preferințe personalizate.
          `}</div>
          <div className="mt-2">{`
Un cookie este format din două părți: numele și conținutul. Durata de viață a unui cookie
este determinată, iar tehnic doar webserverul care a trimis cookie-ul îl poate accesa din
nou în momentul în care utilizatorul revine pe site-ul asociat respectivului webserver.
          `}</div>
          <div className="mt-2">{`
Cookie-urile nu pot fi folosite pentru a transmite viruși informatici sau pentru a rula
programe. Cand accesezi site-ul ca vizitator, un cookie este trimis către browser-ul tău de
internet și salvat pe hard-disk-ul computerului tău. Poți bloca utilizarea cookie-urilor prin
setările făcute în browserul cu care navighezi pe internet.
          `}</div>
          <div className="mt-2">{`
Prin utilizarea cookie-urilor vei beneficia de o mai bună experiență de navigare pe site-ul
nostru. În momentul în care un utilizator accesează site-ul nostru www.urbanmirror.ro, noi
stocăm informații precum: adresa de IP, pagini vizualizate, site-ul de la care ai ajuns pe site-
ul nostru, informații din cookies și alte date tehnice pe care le utilizăm strict pentru
următoarele scopuri:
          `}</div>
          <div className="mt-2 bullet-point-left">{`
Monitorizarea accesului pentru a menține securitatea site-ului și pentru a detecta
accesări potențial periculoase ale site-ului nostru.
          `}</div>
          <div className="mt-2 bullet-point-left">{`
Analiza traficului și surselor de trafic către site-ul nostru, utilizând servicii de
analiză cu scopul de a îmbunătăți serviciile oferite de către noi și de a coordona
strategia de imagine/marketing pentru a ajunge mai ușor la clienții noștri.
          `}</div>
          <div className="mt-2">{`
Toate aceste date sunt date statistice, anonimizate.
          `}</div>
          <div className="mt-2">{`
Fișierele cookie pot fi sub forma de cookie-uri de sesiune sau cookie-uri persistente.
          `}</div>
          <div className="mt-2 bullet-point-left">{`
Cookie-urile temporare - sunt stocate temporar în dosarul &quot;cookie&quot; al browser-ului
web, care le memorează până cand utilizatorul iese de pe site-ul respectiv sau
închide fereastra browser-ului (de exemplu, cand te conectezi/ deconectezi/ de pe
un cont de email sau rețele de socializare)
          `}</div>
          <div className="mt-2 bullet-point-left">{`
Cookie-urile persistente - acestea sunt stocate pe hard disk-ul unui computer sau alt
dispozitiv (și, în general, depind de ciclul de viață presetat al modulelor cookie). Anumite cookie-uri sunt utilizate exclusiv în timpul unei singure sesiuni și nu sunt
memorate dupa ce utilizatorul părăsește site-ul web, în timp ce alte cookie-uri sunt
memorate și reutilizate de fiecare dată când utilizatorul revine pe site-ul respectiv.
          `}</div>
          <div className="mt-2">
            {`Lista cookie-urilor folosite de website-ul `}
            <a href="/" className="link-1">
              {`https://findyourwhy.ro/`}
            </a>
          </div>
          <div className="mt-2 bullet-point-left">{`
Necesare:
          `}</div>
          <div className="mt-2">
            <table className="w-100">
              <tr>
                <th>
                  <b>Denumire:</b>
                </th>
                <th>
                  <b>Furnizor/Tip</b>
                </th>
                <th>
                  <b>Expirare</b>
                </th>
              </tr>

              <tr>
                <td>elementor</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>wc_cart_hash_#</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>wc_fragments_#</td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>
          <div className="mt-2">
            {`Cu toate acestea, `}
            <b>{`cookie-urile pot fi șterse de utilizator în orice moment prin
accesarea setărilor browser-ului, după cum vom arăta mai jos. `}</b>
          </div>

          <div className="mt-2">
            {`
Cookie-urile vor rămâne stocate pe computerul sau pe dispozitivul tău, până când vor fi
șterse sau până cand vor atinge data de expirare.
      `}
          </div>

          <div className="mt-2">
            <b>{`Utilizatorii site-ului pot controla folosirea cookie-urilor la nivelul browser-ului, dar
dacă utilizatorul decide să dezactiveze cookie-urile această acțiune poate determina
o limitare a anumitor caracteristici și funcții în website-ul noastru.`}</b>
          </div>


          <div className="mt-2">
            {`Unele dintre cookie-urile pe care le folosim sunt strict necesare pentru ca site-ul nostru să
funcționeze și asupra acestora nu se cere consimțământul.`}
          </div>

          <div className="mt-2">
            {`Cu toate acestea, `}
            <b>{`pentru acele cookie-uri care sunt utile, dar nu strict necesare, vom
cere mereu consimțământul dvs. înainte de a le plasa. `}</b>
{`Pe lângă cookie-urile pe care le
folosim, lucrând cu terțe părți pentru a vă oferi o experiență oportună, acestea plasează
diverse cookie-urile în calculatorul dvs., însă doar cu consimțământul dvs.`}
          </div>

          <div className="mt-2">
            {`Însă, `}
            <b>{`dacă nu sunteți de acord cu utilizarea niciunui tip de cookie, puteți bloca
oricare cookie din setările browserului tău.`}</b>
          </div>

          <div className="mt-2">
            <b>{`Pașii pentru cele mai utilizate browsere sunt:`}</b>
          </div>

          <div className="mt-2 ml-2">
           {`1) Dacă browser-ul dvs. este Microsoft Internet Explorer 6.0, 7.0, 8.0, poți da click pe
'Instrumente', în colțul superior al navigatorului sau poți selecta 'Opțiuni de
Internet' și apoi click pe 'Confidențialitate'. Selectați setările pe care le doriți, apoi
selectați 'Aplică' și 'Acceptă/OK';`}
          </div>


          <div className="mt-2 ml-2">
           {`2) Dacă browser-ul dvs. este Mozilla Firefox, în meniul navigatorului selectați
'Opțiuni', apoi accesați meniul 'Confidențialitate', iar în meniul care va aparea, alegeți 'Configurare personalizată a istoricului'. Alegeți setările pe care le doriți,
apoi click 'OK/Acceptare';`}
          </div>


          <div className="mt-2 ml-2">
           {`3) Dacă browser-ul este Google Chrome, accesați meniul Chrome care este situat în
colțul superior dreapta, accesați 'Configurare'. Ulterior, alegeți setările pe care le
doriți, apasând 'OK';`}
          </div>


          <div className="mt-2 ml-2">
           {`4) Dacă browser-ul utilizat este Safari, selectați 'Preferințe'. În panoul respectiv
selectați 'Confidențialitate', iar apoi realizați setările pe care le doriți, urmând a da
'OK'.`}
          </div>

          <div className="mt-2">
           {`Pentru orice întrebări suplimentare cu privire la Politica de Cookies, vă rugăm să vă
adresați la următoarea adresă de e-mail: _____________`}
          </div>






        </div>
      </div>

      <div className="p-relative mt-7">
        <Footer />
      </div>
    </div>
  );
}

export default PoliticaDeCookies;
