import './all-speakers-list.scss';
import SpeakerThumbnail from "../../components/speaker-thumbnail/speaker-thumbnail";
import { allEntertainers, allSpeakers } from '../../utils/constants';

function AllSpeakersList() {
  const speakers = [...allEntertainers, ...allSpeakers];
  
  return (
    <div className="all-speakers-list box-sizing-border d-flex w-100 flex-wrap gap-3 justify-content-evenly align-items-center">
    {[...speakers].map((speaker, index) => {
      return (
          <SpeakerThumbnail speaker={speaker} key={"SpeakerThumbnail-" + index}></SpeakerThumbnail>
      );
    })}
  </div>
);
}

export default AllSpeakersList;

