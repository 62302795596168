import {useEffect, useState, useCallback} from "react";
import "./contact-form.scss";
import {FieldValues, useForm} from "react-hook-form";
import {getFunctions, httpsCallable} from "firebase/functions";
import { toast } from "react-toastify";
import { contactPhoneNumbers } from "../../utils/constants";

function ContactForm({onSubmit, onDismiss, isValidated = () => {}}) {
  const {
    register,
    control,
    handleSubmit,
    formState: {errors, isDirty, touchedFields},
    reset,
    setValue,
    setError,
  } = useForm({mode: "onChange"});
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [suggestSpeaker, setSuggestSpeaker] = useState("");
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [suggestSpeakerInput, setSuggestSpeakerInput] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  useEffect(() => {
    console.log("errors", errors);
    if (emailInput && messageInput) {
      isValidated(true);
      setIsFormValid(true);
    } else {
      isValidated(false);
      setIsFormValid(false);
    }
  }, [emailInput, messageInput, suggestSpeaker, suggestSpeakerInput, isValidated, errors]);

  const submit = async (args) => {
    try{
      setIsSendingMessage(true);
      console.log("Submited", args);
      const functions = getFunctions();
      console.log("functions", functions);
      const message = {
        name: args?.nameInput,
        email: args?.emailInput, 
        message: args?.messageInput,
        suggestedSpeaker: args?.suggestSpeakerInput,
      }
      await httpsCallable(functions, "sendContactForm")(message)
      onSubmit(args);
      toast(`Mesaj trimis cu success !`, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "success",
        theme: "dark",
      });

    }catch(err){
      console.error(err);
      toast("Nu am putut trimite mesajul !", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        type: "error",
        theme: "dark",
      });
    }finally{
      setIsSendingMessage(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="">
        <div className="w-100 d-flex gap-1 flex-direction-column">
          <div>
            <h1>Te simți inspirat?</h1>
            <div>
            {`Sună-ne la ${contactPhoneNumbers[0]} sau ${contactPhoneNumbers[1]}`}
            </div>
            <h1>Sau scrie-ne un mesaj:</h1>
          </div>
          <div className="text-left">
            <label className="" htmlFor="nameInput">
              <div className={`${errors?.nameInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Nume`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="nameInput"
                value={nameInput}
                {...register("nameInput", {validate: true})}
                onChange={(e) => {
                  setNameInput(e.target.value);
                  setValue("nameInput", e.target.value, {shouldValidate: true});
                }}></input>
            </label>
          </div>

          <div className="text-left">
            <label className="" htmlFor="emailInput">
              <div className={`${errors?.emailInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`E-mail`}</div>
              </div>
              <input
                className="mt-1 customInput w-100"
                id="emailInput"
                value={emailInput}
                {...register("emailInput", {validate: true})}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                  setValue("emailInput", e.target.value, {shouldValidate: true});
                }}></input>
            </label>
          </div>

          <div className="text-left mt-2">
            <label className="" htmlFor="messageInput">
              <div className={`${errors?.messageInput ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Mesaj`}</div>
              </div>
              <textarea
                className="mt-1 customInput w-100"
                id="messageInput"
                rows="4"
                cols="50"
                value={messageInput}
                {...register("messageInput", {validate: true})}
                onChange={(e) => {
                  setMessageInput(e.target.value);
                  setValue("messageInput", e.target.value, {shouldValidate: true});
                }}></textarea>
            </label>
          </div>

          <div className="text-left custom-checkbox-container mt-2">
            <label className="custom-checkbox" htmlFor="suggestSpeaker">
              <input
                className="mr-2 custom-checkbox"
                id="suggestSpeaker"
                type="checkbox"
                value={suggestSpeaker ? 1 : 0}
                {...register("suggestSpeaker")}
                onChange={(e) => {
                  setSuggestSpeaker(e.target.checked);
                  setValue("suggestSpeaker", e.target.checked, {shouldValidate: true});
                }}></input>
              <span className={`custom-checkmark ${errors.suggestSpeaker ? "custom-input-checkbox-error" : ""}`}></span>
              <div className={`${errors?.suggestSpeaker ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">{`Vreau să propun un speaker`}</div>
              </div>
            </label>
          </div>

          {/* <div className="text-left custom-checkbox-container mt-2">
            <label className="custom-checkbox" htmlFor="legalAgreement">
              <input
                className="mr-2 custom-checkbox"
                id="legalAgreement"
                type="checkbox"
                value={legalAgreement ? 1 : 0}
                {...register("legalAgreement", {validate: true})}
                onChange={(e) => {
                  setLegalAgreement(e.target.checked);
                  setValue("legalAgreement", e.target.checked, {shouldValidate: true});
                }}></input>
              <span className={`custom-checkmark ${errors.legalAgreement ? "custom-input-checkbox-error" : ""}`}></span>
              <div className={`${errors?.legalAgreement ? "custom-input-text-error" : ""}`}>
                <div className="w-100 text-lt-gray">
                  {`Sunt de acord cu `}{" "}
                  <a target="_blank" rel="noopener noreferrer" href="/politica-de-confidentialitate">
                    Politica de Confidențialitate
                  </a>
                </div>
              </div>
            </label>
          </div> */}

          {suggestSpeaker && (
            <div className="text-left mt-2">
              <label className="" htmlFor="suggestSpeakerInput">
                <div className={`${errors?.suggestSpeakerInput ? "custom-input-text-error" : ""}`}>
                  <div className="w-100 text-lt-gray">{`Numele speakerului`}</div>
                </div>
                <input
                  className="mt-1 customInput w-100"
                  id="suggestSpeakerInput"
                  value={suggestSpeakerInput}
                  {...register("suggestSpeakerInput", {validate: true})}
                  onChange={(e) => {
                    setSuggestSpeakerInput(e.target.value);
                    setValue("suggestSpeakerInput", e.target.value, {shouldValidate: true});
                  }}></input>
              </label>
            </div>
          )}

          <div className="text-sm mt-2">
            {`* Apăsând pe butonul "Trimite" îți dai consimțământul că esți de acord cu `}{" "}
            <a target="_blank" rel="noopener noreferrer" href="/politica-de-confidentialitate">
              Politica de Confidențialitate
            </a>
          </div>
        </div>
        <div></div>
        <div className={`input-error ${errors.length ? "" : "invisible"}`}>
          {"Completează toate câmpurile !"}
        </div>

        <div className="modal-action-area text-center d-flex mt-2 justify-content-around flex-wrap gap-2">
              <div
                className={"button cancel-button flex-1 d-flex justify-content-center"}
                onClick={() => {
                  onDismiss();
                }}>
                {"Cancel"}
              </div>
              <div
                className={`p-relative button confirm-button flex-1 d-flex justify-content-center ${
                  isFormValid ? "" : "disabled"
                } ${
                  isSendingMessage ? "disabled" : ""
                }`}>
                {isSendingMessage ? "Se trimite..." : "Trimite"}

                {
                  !isSendingMessage ? <input className="ghost-over cursor-pointer" type="submit" name="submitButton"></input> : <></>
                }
                

              </div>
            </div>

      </form>
    </>
  );
}
export default ContactForm;
