import "./styles/global.scss";
import {useEffect} from "react";
import Home from "./pages/home/home";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./pages/layout/layout";
import TermsConditions from "./pages/legal/terms-conditions";
import PoliticaDeConfidentialitate from "./pages/legal/politica-de-confidentialitate";
import PoliticaDeCookies from "./pages/legal/politica-de-cookies";
import AcordNewsletter from "./pages/legal/acord-newsletter";
import Speakers from "./pages/speakers/speakers";
import Sponsors from "./pages/sponsors/sponsors";
import {FirebaseAppProvider} from "reactfire";
import BecomeSpeaker from "./pages/become-speaker/become-speaker";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_PUBLIC_FIREBASE_MEASUREMENT_ID
};

function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/termeni-si-conditii" element={<TermsConditions />} />
              <Route path="/politica-de-confidentialitate" element={<PoliticaDeConfidentialitate />} />
              <Route path="/politica-de-cookies" element={<PoliticaDeCookies />} />
              <Route path="/acord-newsletter" element={<AcordNewsletter />} />
              <Route path="/ce-spun-speakerii/:speakerId?" element={<Speakers />} />
              <Route path="/devino-speaker" element={<BecomeSpeaker />} />
              <Route path="/sponsori/:sponsorId?" element={<Sponsors />} />
              {/* <Route path="blogs" element={<Blogs />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
    </FirebaseAppProvider>
  );
}

export default App;
