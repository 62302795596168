import { useState, useContext } from "react";
import BurgerIcon from "../../animated-icons/burger-icon/burger-icon";
import NavMenuButton from "../nav-menu-button/nav-menu-button";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../../components/modals/alert-modal/alert-modal";
import "./nav-menu-bar.scss";
import { useLocation } from "react-router-dom";

function NavMenuBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { handleModal } = useContext(ModalContext);

  const location = useLocation();

  const openContactModal = () => {
    return new Promise((resolve, reject) => {
      handleModal(
        <AlertModal
          type={alertModalTypes.Contact}
          className="modal-wide"
          title="Contactează-ne"
          successLabel="Trimite"
          cancelLabel="Anulează"
          onDismiss={reject}
          onSuccess={() => resolve()}></AlertModal>
      );
    });
  };

  return (
    <div className="nav-menu-bar-container">
      <div className="nav-menu-bar">
        <div className="big-container d-flex">
          <div className="brand-identity">
            <a href="/#top">
              <img
                src={require("./../../assets/logo.webp")}
                className="logo"
                width="150px"
                height="150px"
                alt="find-your-why-by-flony-motivational"></img>
            </a>
            <div className="logo-slogan">Show, Motivație, Inspirație</div>
          </div>
          <div className="container pl-5 pr-5 nav-buttons-container">
            <NavMenuButton
              content="Acasă"
              link="/"
              isActive={location?.pathname === "/" || !location?.pathname?.length}></NavMenuButton>
            <NavMenuButton
              content="Speakeri"
              link="/ce-spun-speakerii"
              isActive={location?.pathname?.includes("/ce-spun-speakerii")}></NavMenuButton>
            {/* <NavMenuButton content="Sponsori" link="#sponsori" isActive={location?.pathname?.includes("/sponsori")}></NavMenuButton> */}
            <NavMenuButton content="Bilete" link="/#bilete" isActive={false}></NavMenuButton>
            <NavMenuButton
              content="Devino Speaker"
              link="/devino-speaker"
              isActive={location?.pathname?.includes("/devino-speaker")}></NavMenuButton>
            <NavMenuButton content="Contact" link="" isActive={false} onClick={openContactModal}></NavMenuButton>
          </div>
          <div onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="burger-menu-container">
            <div className={`burger-button animate-on-hover ${isMobileMenuOpen ? "burger-opened" : ""}`}>
              <BurgerIcon />
            </div>
            <div className={`mobile-menu ${isMobileMenuOpen ? "mobile-active" : ""}`}>
              <a id="nav/acasa" href="/" className="menu-item" tabIndex={0}>
                Acasă
              </a>
              <a id="nav/ce-spun-speakerii" href="/ce-spun-speakerii" className="menu-item" tabIndex={0}>
                Speakeri
              </a>
              {/* <a id="nav/Sponsori" href="#sponsori" className="menu-item" tabIndex={0}>
                Sponsori
              </a> */}
              <a id="nav/bilete" href="/#bilete" className="menu-item" tabIndex={0}>
                Bilete
              </a>
              {/* <HashLink id="nav/bilete" className="menu-item" to="/#bilete">Bilete</HashLink> */}

              {/* <a id="nav/Devino-speaker" href="#devino-speaker" className="menu-item" tabIndex={0}>
                Devino-speaker
              </a> */}
              <div id="nav/Contact" onClick={openContactModal} className="menu-item" tabIndex={0}>
                Contact
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavMenuBar;
