import { useEffect, useState, useCallback, useMemo } from "react";
import "./days-selector.scss";
import { FieldValues, useForm } from "react-hook-form";
import { eventDates, eventDaysOptionValues } from "../../utils/constants";

function DaysSelector({ onSubmit, onDismiss, isValidated = () => {}, cancelLabel, successLabel, extraData = {} }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
    reset,
    setValue,
    setError,
  } = useForm({ mode: "onBlur", defaultValues: { selectedDays: null } });
  // const [dayOneCheckbox, setDayOneCheckbox] = useState(false);
  // const [dayTwoCheckbox, setDayTwoCheckbox] = useState(false);
  // const [bothDaysCheckbox, setBothDaysCheckbox] = useState(false);
  const [selectedDays, setSelectedDays] = useState();
  const [isFormValidated, setIsFormValidated] = useState(false);

  // const validateSelectedDays = useCallback(
  //   (selectedDays) => {
  //     return dayOneCheckbox || dayTwoCheckbox;
  //   },
  //   [dayOneCheckbox, dayTwoCheckbox]
  // );

  // useEffect(() => {
  //   setIsFormValidated(validateSelectedDays());
  // }, [dayOneCheckbox, dayTwoCheckbox, setIsFormValidated, validateSelectedDays]);

  useEffect(() => {
    console.log("Selected Days", selectedDays);
    if(selectedDays)
    setIsFormValidated(true);
  }, [selectedDays]);

  useEffect(() => {
    isValidated(isFormValidated);
  }, [isFormValidated, isValidated]);

  const submit = (args) => {
    console.log("Submited", args);
    onSubmit(args);
  };

  const eventDaysOptions = useMemo(() => {
    return [
      {
        value: eventDaysOptionValues[0].value,
        dayName: eventDaysOptionValues[0].name,
        dayOfWeek:
          eventDates[0].toLocaleDateString("ro-RO", { weekday: "long" }).charAt(0).toUpperCase() +
          eventDates[0].toLocaleDateString("ro-RO", { weekday: "long" }).slice(1),
        fullDate: eventDates[0].toLocaleDateString("ro-RO", { day: "numeric", month: "long", year: "numeric" }),
        price: `
          ${
            extraData?.offer?.isOnSale
              ? extraData?.offer?.prices[extraData?.activePriceTimeline].salePrice
              : extraData?.offer?.prices[extraData?.activePriceTimeline].price
          } ${extraData?.offer?.currency}`,
      },
      {
        value: eventDaysOptionValues[1].value,
        dayName: eventDaysOptionValues[1].name,
        dayOfWeek:
          eventDates[1].toLocaleDateString("ro-RO", { weekday: "long" }).charAt(0).toUpperCase() +
          eventDates[1].toLocaleDateString("ro-RO", { weekday: "long" }).slice(1),
        fullDate: eventDates[1].toLocaleDateString("ro-RO", { day: "numeric", month: "long", year: "numeric" }),
        price: `
            ${
              extraData?.offer?.isOnSale
                ? extraData?.offer?.prices[extraData?.activePriceTimeline].salePrice
                : extraData?.offer?.prices[extraData?.activePriceTimeline].price
            } ${extraData?.offer?.currency}`,
      },
      {
        value: eventDaysOptionValues[2].value,
        dayName: eventDaysOptionValues[2].name,
        dayOfWeek: `${eventDates[0]
          .toLocaleDateString("ro-RO", { weekday: "long" })
          .charAt(0)
          .toUpperCase()}${eventDates[0].toLocaleDateString("ro-RO", { weekday: "long" }).slice(1)}
           și ${eventDates[1].toLocaleDateString("ro-RO", { weekday: "long" }).charAt(0).toUpperCase()}${eventDates[1]
          .toLocaleDateString("ro-RO", { weekday: "long" })
          .slice(1)}`,
        fullDate: `${eventDates[0].toLocaleDateString("ro-RO", {
          day: "numeric",
        })} - ${eventDates[1].toLocaleDateString("ro-RO", { day: "numeric" })}
            ${eventDates[1].toLocaleDateString("ro-RO", { month: "long", year: "numeric" })}
            `,
        // eventDates[1]
        //   .toLocaleDateString("ro-RO", { day: "numeric", month: "long", year: "numeric" })
        //   .charAt(0)
        //   .toUpperCase() +
        // eventDates[1].toLocaleDateString("ro-RO", { day: "numeric", month: "long", year: "numeric" }).slice(1),
        price: `
            ${
              extraData?.offer?.isOnSale
                ? extraData?.offer?.prices[extraData?.activePriceTimeline].salePriceForTwoDays
                : extraData?.offer?.prices[extraData?.activePriceTimeline].priceForTwoDays
            } ${extraData?.offer?.currency}`,
      },
    ];
  }, [
    extraData?.activePriceTimeline,
    extraData?.offer?.currency,
    extraData?.offer?.isOnSale,
    extraData?.offer?.prices,
  ]);
  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="">
        {/* <div className="d-flex gap-1 justify-content-start flex-wrap">
          <div className="text-left custom-checkbox-container">
            <label className="custom-checkbox" htmlFor="dayOneCheckbox">
              <input
                className="mr-2 custom-checkbox"
                id="dayOneCheckbox"
                type="radio"
                value={dayOneCheckbox ? 1 : 0}
                {...register("dayOneCheckbox", { validate: validateSelectedDays })}
                onChange={(e) => {
                  setDayOneCheckbox(e.target.checked);
                  setValue("dayOneCheckbox", e.target.checked, { shouldValidate: true });
                }}></input>
              <span className={`custom-checkmark ${errors.dayOneCheckbox ? "custom-input-checkbox-error" : ""}`}></span>
              <div className={`${errors?.dayOneCheckbox ? "custom-input-text-error" : ""}`}>
                <div className="text-white d-flex gap-2">
                  <div>{`${eventDaysOptions[0].dayName}, ${eventDaysOptions[0].dayOfWeek}, ${eventDaysOptions[0].fullDate}`}</div>
                  <div className="w-fit font-black text-underline text-underline-black underline-variant-7">
                    {eventDaysOptions[0].price}
                  </div>
                </div>
              </div>
            </label>
          </div>

          <div className="text-left custom-checkbox-container mt-1 pt-1">
            <label className="custom-checkbox" htmlFor="dayTwoCheckbox">
              <input
                className="mr-2 custom-checkbox"
                id="dayTwoCheckbox"
                type="radio"
                value={dayTwoCheckbox ? 1 : 0}
                {...register("dayTwoCheckbox", { validate: validateSelectedDays })}
                onChange={(e) => {
                  setDayTwoCheckbox(e.target.checked);
                  setValue("dayTwoCheckbox", e.target.checked, { shouldValidate: true });
                }}></input>
              <span className={`custom-checkmark ${errors.dayTwoCheckbox ? "custom-input-checkbox-error" : ""}`}></span>
              <div className={`${errors?.dayTwoCheckbox ? "custom-input-text-error" : ""}`}>
                <div className="text-white d-flex gap-2">
                  <div>{`${eventDaysOptions[1].dayName}, ${eventDaysOptions[1].dayOfWeek}, ${eventDaysOptions[1].fullDate}`}</div>
                  <div className="w-fit font-black text-underline text-underline-black underline-variant-7">
                    {eventDaysOptions[1].price}
                  </div>
                </div>
              </div>
            </label>
          </div>

          <div className="text-left custom-checkbox-container">
            <label className="custom-checkbox" htmlFor="bothDaysCheckbox">
              <input
                className="mr-2 custom-checkbox"
                id="bothDaysCheckbox"
                type="radio"
                value={bothDaysCheckbox ? 1 : 0}
                {...register("bothDaysCheckbox", { validate: validateSelectedDays })}
                onChange={(e) => {
                  setBothDaysCheckbox(e.target.checked);
                  setValue("bothDaysCheckbox", e.target.checked, { shouldValidate: true });
                }}></input>
              <span
                className={`custom-checkmark ${errors.bothDaysCheckbox ? "custom-input-checkbox-error" : ""}`}></span>
              <div className={`${errors?.bothDaysCheckbox ? "custom-input-text-error" : ""}`}>
                <div className="text-white d-flex gap-2 align-items-center">
                  <div>
                    <div>{`${eventDaysOptions[2].dayName}, ${eventDaysOptions[2].dayOfWeek}`}</div>
                    <div>{`${eventDaysOptions[2].fullDate}`}</div>
                  </div>

                  <div className="w-fit font-black text-underline text-underline-black underline-variant-7">
                    {eventDaysOptions[2].price}
                  </div>
                </div>
              </div>
            </label>
          </div>
        </div> */}

        <fieldset
          onChange={(e) => {
            setSelectedDays(e.target.value);
            setValue("selectedDays", e.target.value, { shouldValidate: true });
          }}
          className="mt-2 d-flex flex-wrap justify-content-start flex-direction-column gap-2 styled-radio">
          <div className="d-flex align-items-center p-relative">
            <label htmlFor="radio-day1-selector">
              <input
                {...register("selectedDays", { required: true })}
                type="radio"
                id="radio-day1-selector"
                name="radio-day1-selector"
                value={eventDaysOptions[0].value}
              />

              <div className="text-white d-flex gap-2 align-items-center">
                <div>{`${eventDaysOptions[0].dayName}, ${eventDaysOptions[0].dayOfWeek}, ${eventDaysOptions[0].fullDate}`}</div>
                <div className="w-fit font-black text-underline text-underline-black underline-variant-7">
                  {eventDaysOptions[0].price}
                </div>
              </div>
            </label>
          </div>

          <div className="d-flex align-items-center p-relative mt-2">
            <label htmlFor="radio-day2-selector">
              <input
                {...register("selectedDays", { required: true })}
                type="radio"
                id="radio-day2-selector"
                name="radio-day2-selector"
                value={eventDaysOptions[1].value}
              />

              <div className="text-white d-flex gap-2 align-items-center">
                <div>{`${eventDaysOptions[1].dayName}, ${eventDaysOptions[1].dayOfWeek}, ${eventDaysOptions[1].fullDate}`}</div>
                <div className="w-fit font-black text-underline text-underline-black underline-variant-7">
                  {eventDaysOptions[1].price}
                </div>
              </div>
            </label>
          </div>

          <div className="d-flex align-items-center p-relative mt-1">
            <label htmlFor="radio-day3-selector">
              <input
                {...register("selectedDays", { required: true })}
                type="radio"
                id="radio-day3-selector"
                name="radio-day2-selector"
                value={eventDaysOptions[2].value}
              />

              <div className="text-white d-flex gap-2 align-items-center">
                <div>
                  <div>{`${eventDaysOptions[2].dayName}, ${eventDaysOptions[2].dayOfWeek}`}</div>
                  <div>{`${eventDaysOptions[2].fullDate}`}</div>
                </div>

                <div className="w-fit font-black text-underline text-underline-black underline-variant-7">
                  {eventDaysOptions[2].price}
                </div>
              </div>

            </label>
          </div>
        </fieldset>

        <div className={`text-center input-error ${isDirty && !selectedDays ? "" : "invisible"}`}>
          {"Selectează cel puțin o zi !"}
        </div>

        <div className="modal-action-area text-center d-flex mt-2 justify-content-around flex-wrap gap-2">
          <div
            className={"button cancel-button flex-1 d-flex justify-content-center"}
            onClick={() => {
              onDismiss();
            }}>
            {cancelLabel || "Cancel"}
          </div>
          <button
            className={`button confirm-button flex-1 d-flex justify-content-center text-nowrap ${
              isFormValidated ? "" : "disabled"
            }`}
            type="submit">
            {successLabel || "OK"}
          </button>
        </div>
      </form>
    </>
  );
}
export default DaysSelector;
