import React, { useContext } from "react";
import PackageOffer from "../../components/package-offer/package-offer";
import { ModalContext } from "../../utils/modal/modalContext";
import AlertModal, { alertModalTypes } from "../../components/modals/alert-modal/alert-modal";
import "./tickets.scss";
import SplineSupreme from "../spline-supreme/spline-supreme";
import { activePriceTimeline, eventDaysOptionValues, packageOffers, promotionNames, ticketsUrl } from "../../utils/constants";
import { useAnalytics } from "reactfire";
import { logEvent } from "firebase/analytics";
import ReactGA from 'react-ga4';
import { openSafelyInNewTab } from "../../utils/utils";

function Tickets() {
  const { handleModal } = useContext(ModalContext);
  const analytics = useAnalytics();
  
  const trackTicketBuy = (index, offer, selectedTicket, _activePriceTimeline) => {
    let boughtItem = {
      item_id: offer.id,
      index: index,
      item_name: offer.title,
      price: offer.isOnSale ? offer.prices[activePriceTimeline].salePrice : offer.prices[activePriceTimeline].price,
      discount: offer.isOnSale
        ? offer.prices[activePriceTimeline].price - offer.prices[activePriceTimeline].salePrice
        : 0,
      quantity: 1,
      promotion_name: promotionNames[activePriceTimeline],
      currency: offer.currency,
    };
    let analyticsObject = {
      items: [boughtItem],
      currency: offer.currency,
      value: offer.isOnSale ? offer.prices[activePriceTimeline].salePrice : offer.prices[activePriceTimeline].price,
    };

    // index 0 = Standard package, per days
    console.log("eventDaysOptionValues[2].value", eventDaysOptionValues[2].value);
    console.log("selectedTicket?.selectedDays", selectedTicket?.selectedDays);
    if (index === 0 && selectedTicket?.selectedDays === eventDaysOptionValues[2].value) {
      // Selected 2 days
      boughtItem = {
        ...boughtItem,
        price: offer.isOnSale
          ? offer.prices[activePriceTimeline].salePriceForTwoDays
          : offer.prices[activePriceTimeline].priceForTwoDays,
        discount: offer.isOnSale
          ? offer.prices[activePriceTimeline].priceForTwoDays - offer.prices[activePriceTimeline].salePriceForTwoDays
          : 0,
        quantity: 2,
      };
      analyticsObject = {
        items: [boughtItem],
        currency: offer.currency,
        value: offer.isOnSale ? offer.prices[activePriceTimeline].salePriceForTwoDays : offer.prices[activePriceTimeline].priceForTwoDays,
      }
    }

    console.log("analyticsObject", analyticsObject);

    // Firebase Analytics
    logEvent(analytics, "add_to_cart", analyticsObject);

    // GA4
    ReactGA.event({
      category: 'Ecommerce',
      action: 'Purchase',
      label: 'Start Purchase (redirect to iabilet.ro)',
      value: analyticsObject.value,
      ecommerce: {
        ...analyticsObject,
        transaction_id: analyticsObject.item_id+"_timestamp_"+Date.now().toString()+"_rand_"+Math.random().toString().replace(".", "")
      }
    });

    // Google Ads
    window?.gtag('event', 'conversion', {'send_to': `${process.env.REACT_APP_PUBLIC_GOOGLE_ADS_TAG}/${offer.id}`});

    
    // Meta tracking _ attempt 2
    // 
    // window?.fbq('trackCustom', 'Start Purchase (redirect to iabilet.ro)', analyticsObject);
    // window?.fbq('track', 'Start Purchase (redirect to iabilet.ro)', analyticsObject)
  }

  const buyTickets = (index, offer, selectedTicket, _activePriceTimeline) => {
    console.log("buyTickets");
    console.log(
      "index, offer, selectedTicket, _activePriceTimeline",
      index,
      offer,
      selectedTicket,
      _activePriceTimeline
    );
    trackTicketBuy(index, offer, selectedTicket, _activePriceTimeline);
  };

  const trackTicketView = (index, offer, _activePriceTimeline) => {
    const analyticsObject = {
      items: [
        {
          item_id: offer.id,
          index: index,
          item_name: offer.title,
          price: offer.isOnSale
            ? offer.prices[activePriceTimeline].salePrice
            : offer.prices[activePriceTimeline].price,
          discount: offer.isOnSale
            ? offer.prices[activePriceTimeline].price - offer.prices[activePriceTimeline].salePrice
            : 0,
          quantity: 1,
          promotion_name: promotionNames[activePriceTimeline],
          currency: offer.currency,
        },
        {
          item_id: offer.id,
          index: index,
          item_name: offer.title,
          price: offer.isOnSale
            ? offer.prices[activePriceTimeline].salePriceForTwoDays
            : offer.prices[activePriceTimeline].priceForTwoDays,
          discount: offer.isOnSale
            ? offer.prices[activePriceTimeline].priceForTwoDays -
              offer.prices[activePriceTimeline].salePriceForTwoDays
            : 0,
          quantity: 1,
          promotion_name: promotionNames[activePriceTimeline],
          currency: offer.currency,
        },
      ],
      currency: offer.currency,
      value: offer.isOnSale ? offer.prices[activePriceTimeline].salePrice : offer.prices[activePriceTimeline].price,
    };

    console.log("analyticsObject", analyticsObject);

    logEvent(analytics, "view_item", analyticsObject);
  }

  const handleBuyTicketButton = (index, offer, _activePriceTimeline) => {
    return new Promise((resolve, reject) => {

      // 
      // Version 1 - open the modal and then send to iabilet.ro
      // 
      // trackTicketView(index, offer, _activePriceTimeline);
      // handleModal(
      //   <AlertModal
      //     // index 0 = Standard package, per days
      //     type={index === 0 ? alertModalTypes.SelectDays : alertModalTypes.Confirm}
      //     className="modal-wide"
      //     title={`Cumpăra ${offer.title}`}
      //     extraData={{offer: offer, activePriceTimeline: activePriceTimeline}}
      //     successLabel="Cumpără bilet"
      //     cancelLabel="Anulează"
      //     onDismiss={reject}
      //     onSuccess={(selectedTickets) => {
      //       resolve(selectedTickets);
      //       buyTickets(index, offer, selectedTickets, _activePriceTimeline);
      //     }}>
      //     {index === 0 ? (
      //       <>
      //           <h4 className="text-bold mt-1 mb-0 text-center">Alege zilele în care vei veni</h4>
      //       </>
      //     ) : (
      //       <>
      //         <div className="pb-2">
      //           <h4 className="text-bold mt-1 text-center">Mergi către tickete.ro</h4>
      //         </div>
      //       </>
      //     )}
      //   </AlertModal>
      // );



      // 
      // Version 2 - directly redirect to iabilet.ro
      // 
      trackTicketBuy(index, offer, null, _activePriceTimeline)
      openSafelyInNewTab(ticketsUrl)
    });
  };

  return (
    <>
      <div className="d-flex justify-content-center flex-wrap package-offers-container animate__animated animate__zoomInDown reveal-on-scroll">
        {packageOffers.map((offer, index) => {
          if (!offer.isSupreme)
            return (
              <div className="mt-5 mr-2 ml-2" key={"package-offer-" + index}>
                <PackageOffer
                  offer={offer}
                  activePriceTimeline={activePriceTimeline}
                  onClick={() => handleBuyTicketButton(index, offer, activePriceTimeline)}></PackageOffer>
              </div>
            );
          else return null;
        })}
      </div>

      <div className="small-container mt-4">
        {packageOffers
          .filter((offer) => offer.isSupreme)
          ?.map((offer, index) => {
            return (
              <div className="" key={"package-offer-" + index}>
                <SplineSupreme
                  offer={offer}
                  activePriceTimeline={activePriceTimeline}
                  onClick={() =>
                    handleBuyTicketButton(
                      packageOffers.map((offer) => offer.isSupreme).indexOf(true),
                      offer,
                      activePriceTimeline
                    )
                  }
                />
              </div>
            );
          })}
      </div>

      {/* <div className="d-flex justify-content-center flex-wrap package-offers-container animate__animated animate__zoomInDown reveal-on-scroll">
        <div className="supreme-package">
          <img src={supremePackageDesign} alt="supreme package"></img>
        </div>
      </div> */}

      {/* <div className="d-flex justify-content-center flex-wrap package-offers-container">
      <div className="supreme-package">
        <SplineDiamond offer={offer} activePriceTimeline={activePriceTimeline} onClick={() => handleBuyTicketButton(index, offer.prices)}/>
      </div>
    </div> */}
    </>
  );
}
export default Tickets;
