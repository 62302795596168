import {useEffect, useRef, useState, useMemo} from "react";
import "./floating-menu.scss";
import {useDebouncedCallback} from "use-debounce";
import {useLocation} from "react-router-dom";

const heightOfOneItem = 175;
const menuItems = [
  {
    title: "Despre",
    url: "despre",
  },
  {
    title: "Speakeri",
    url: "speakeri",
  },
  {
    title: "Show",
    url: "show",
  },
  {
    title: "Bilete",
    url: "bilete",
  },
  {
    title: "Oferte",
    url: "oferte",
  },
  {
    title: "Locație",
    url: "locatie",
  },
];
const defaultIntersectionOptions = {
  root: null,
  rootMargin: "0px",
  threshold: [
    0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1, 0,
  ],
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const {search} = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function FloatingMenu() {
  let query = useQuery();

  const isItemActive = (index) => {
    if (index === 1) return true;
    return false;
  };

  useEffect(() => {
    setTimeout(() => {
      const intersectionObservers = [];

      initMenuTracking(
        intersectionObservers,
        menuItems.map((i) => i.url)
      );
      return () => {
        for (let intrObs of intersectionObservers) {
          console.log(intrObs);
          intrObs.disconnect();
        }
      };
    }, 500);
  }, []);

  const initMenuTracking = (intersectionObservers, sectionIDs) => {
    let intersectionOptions = {
      root: defaultIntersectionOptions.root,
      rootMargin: `0px -20% 40% 0px`,
      threshold: [0.15, 0.5, 1.0],
    };
    let intersectionObserver = new IntersectionObserver(handleMenuTracking, intersectionOptions);

    for (let sectionID of sectionIDs) {
      let element = document.getElementById(sectionID);
      intersectionObserver.observe(element);
    }
    intersectionObservers.push(intersectionObserver);
  };

  const handleMenuTracking = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // console.log(entry.target.id, "is IN", entry.intersectionRatio, "%")
        document.getElementById("menu/" + entry.target.id).classList.add("active");
      } else {
        // console.log(entry.target.id, " is OUT");
        document.getElementById("menu/" + entry.target.id).classList.remove("active");
      }
    });
  };

  // scrollToProperProject(): void {
  //   // console.log(previousRoute);
  //   if (previousRoute != '/') {
  //     let projectID = -1;
  //     // console.log("comes from", previousRoute);
  //     switch (previousRoute.split('#')[0]) {
  //       case '/izibac': {
  //         console.log('izi');
  //         projectID = 0;
  //         break;
  //       }
  //       case '/peteco': {
  //         console.log('peteco');
  //         projectID = 1;
  //         break;
  //       }
  //       case '/scoala-de-business': {
  //         console.log('scoala-de-business');
  //         projectID = 2;
  //         break;
  //       }
  //     }
  //     console.log(projectID);
  //     if (projectID != -1) {
  //       setTimeout(
  //         function () {
  //           console.log('scrolling back');
  //           document
  //             .getElementById('project-card-container-' + projectID)
  //             .scrollIntoView({
  //               behavior: 'smooth',
  //               block: 'center',
  //               inline: 'nearest',
  //             });
  //           document.getElementById('menu/intro').classList.add('visible');
  //         }.bind(document),
  //         100
  //       );
  //     } else {
  //       // console.log("Not a project route")
  //     }
  //   } else {
  //     // console.log("first load");
  //   }
  // }

  return (
    <div className="floating-items-list-container">
      <div className="items-list-container">
        {menuItems.map((item, index) => {
          return (
            <div key={"menu-item-" + index}>
              <a href={`#${item.url}`} className="floating-item-container">
                <div id={`menu/${item.url}`} className={`floating-item`}>
                  <div className="">{item.title}</div>
                </div>
              </a>
              {index < menuItems.length - 1 ? (
                <div className="breadcrumbs">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FloatingMenu;
