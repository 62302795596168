import "./legal.scss";
import CustomCursor from "../../components/custom-cursor/custom-cursor";
import Footer from "../../components/footer/footer";

function PoliticaDeConfidentialitate() {
  return (
    <div className="Home">
      <CustomCursor />

      <div className="p-relative mt-7 text-white font-normal">
        <div className="container">
          <h1 className="h0 text-hard-glow-white" glow-text="Politica de Confidențialitate">
            Politica de Confidențialitate
          </h1>
          <h5
            className="h5 text-hard-glow-white"
            glow-text="Politica de confidențialitate a website-ului 'https://findyourwhy.ro/'">
            Politica de confidențialitate a website-ului 'https://findyourwhy.ro/'
          </h5>

          <div className="mt-4">
            {`
      Politica de confidențialitate a website-ului 'https://findyourwhy.ro/'
      Politica de confidențialitate vă informează despre informațiile pe care le colectăm de la
      dumneavoastră atunci când utilizați site-ul nostru. În colectarea acestor informații, noi acționăm
      în calitate de operator de date personale și, prin lege, suntem obligați să vă furnizăm informații
      despre noi, despre scopul și modul în care folosim datele dvs. și despre drepturile pe care le
      aveți asupra datelor dvs. personale.
      `}
          </div>
          <div className="mt-2">
            {`
      Politica și practicile noastre de protejare a datelor se focusează pe procesarea, schimbul și
      stocarea corespunzătoare și legală a informațiilor cu caracter personal și totodată pe asigurarea
      confidențialității, integrității și disponibilității.
      `}
          </div>
          <div className="mt-2">
            {`
      Prin urmare, prin lectura politicii de confidențialitate, veți afla toate drepturile dumneavoastră
      și toate informațiile în legătură cu prelucrarea datelor personale.
      Politica de confidențialitate a datelor se bazează pe următoarele principii de protecție a datelor:
      `}
          </div>
          <div className="mt-2">
            {`
      Politica de confidențialitate a datelor se bazează pe următoarele principii de protecție a datelor:
      `}
          </div>

          <div className="mt-2 bullet-point-left">
            {`
      Procesarea datelor personale se va face într-o manieră legală, corectă și transparentă;
      `}
          </div>
          <div className="mt-2 bullet-point-left">
            {`
      Colectarea datelor personale se va face numai în scopuri specificate, explicite și legitime
      și datele nu vor fi procesate mai departe într-o manieră incompatibilă cu acele scopuri;
      `}
          </div>
          <div className="mt-2 bullet-point-left">
            {`
      Colectarea datelor personale va fi adecvată, relevantă și limitată la informațiile necesare
      scopului procesării;
      `}
          </div>

          <div className="mt-2 bullet-point-left">
            {`
      Datele personale vor fi reale și, acolo unde este necesar, actualizate;
      `}
          </div>
          <div className="mt-2 bullet-point-left">
            {`
      Se vor lua toate măsurile necesare pentru a se asigura că datele incorecte sunt șterse sau
      corectate fără întârziere;
      `}
          </div>
          <div className="mt-2 bullet-point-left">
            {`
      Datele personale vor fi păstrate într-o formă ce permite identificarea persoanei vizate și
      pentru o perioadă nu mai lungă decât cea în care datele personale sunt procesate;
      `}
          </div>
          <div className="mt-2 bullet-point-left">
            {`
      Toate datele personale vor fi păstrate confidențial și stocate într-o manieră ce asigură
      securitatea necesară;
      `}
          </div>
          <div className="mt-2 bullet-point-left">
            {`
      Datele personale nu vor fi distribuite persoanelor terțe decât în cazul în care este
      necesar în scopul oferirii de servicii;
      `}
          </div>
          <div className="mt-2 bullet-point-left">
            {`
      Persoanele vizate au dreptul de a solicita accesul la datele personale, rectificarea și
      ștergerea acestora, împotrivirea sau restricționarea de la procesarea datelor atât cât și
      de la dreptul portabilității datelor.
      `}
          </div>

          <div className="mt-4">
            {`
      Datele personale:
      `}
          </div>
          <div className="mt-2">
            {`
      Date personale înseamnă `}
            <b>orice informație care poate fi legată de o persoană fizică identificată sau identificabilă</b>{" "}
            {` (persoana subiect). Datele personale includ toate tipurile de
      informații directe sau indirecte (și anume utilizate în legătură cu alte date) ce se referă la persoana subiect, cum ar fi nume, data de naștere, adrese, adrese de e-mail, numere de telefon,
      etc.
      `}
          </div>

          <div className="mt-4 ml-3">
            <b>{`1. Cine suntem noi?`}</b>
          </div>
          <div className="mt-2">
            <table className="w-100">
              <tr>
                <td>
                  <b>Numele organizației:</b>
                </td>
                <td>FLONY MOTIVATION S.R.L.</td>
              </tr>
              <tr>
                <td>
                  <b>Cod unic de identificare:</b>
                </td>
                <td>46620930</td>
              </tr>
              <tr>
                <td>
                  <b>Adresa sediului:</b>
                </td>
                <td>Costești, oraș Costești, str. Victoriei, bl. Z1, sc. C, et. 1, ap.2, camera nr. 1, jud.</td>
              </tr>
              <tr>
                <td>
                  <b>E-mail:</b>
                </td>
                <td>office@flony.ro</td>
              </tr>
            </table>
          </div>

          <div className="mt-4 ml-3">
            <b>{`2. Cum folosim informațiile dumneavoastră?`}</b>
          </div>

          <div className="mt-4">
            <b>{`2.1 Când folosiți site-ul nostru`}</b>
          </div>
          <div className="mt-2">
            {`Atunci când utilizați site-ul nostru pentru a vizualiza informațiile pe care le punem la dispoziție,
un număr de cookie-uri sunt utilizate de noi (cookies first party) și de terțe părți (cookies third
party) pentru a permite funcționarea site-ului, pentru a colecta informații utile despre vizitatori
și pentru a vă oferi o experiență cât mai bună în utilizarea site-ului nostru.`}
          </div>

          <div className="mt-4">
            <b>{`2.2. Ce sunt cookie-urile?`}</b>
          </div>
          <div className="mt-2">
            {`Cookie-urile sunt mici fișiere text care sunt plasate pe dispozitivul utilizatorului pentru a urmări
modul de utilizare și a înregistra preferințele. Cookie-urile noastre nu conțin informații care pot
indentifica persoane. Colectăm anumite informații automatizat prin utilizarea cookie-urilor și
tehnologiilor de urmărire cum ar fi adrese Internet Protocol (IP), tip de browser, Furnizor
Internet (Internet Service Provider - ISP), referințe/ieșiri pagini, paginile vizualizate pe site-ul
nostru (ex. pagini HTML, grafice), sistem operare, data/ora și/sau seria de click-uri pentru a
analiza trendul ca ansamblu și a administra site-ul.`}
            <b>{`Utilizatorii site-ului pot controla folosirea
cookie-urilor la nivelul browser-ului, dar dacă utilizatorul decide să dezactiveze cookie-
urile această acțiune va determina o limitare a anumitor caracteristici și funcții în
website-ul și serviciile noastre.`}</b>
          </div>

          <div className="mt-2">
            {`Unele dintre cookie-urile pe care le folosim sunt strict necesare pentru ca site-ul nostru să
funcționeze și asupra cărora nu se cere consimțământul.`}
          </div>
          <div className="mt-2">
            {`Cu toate acestea, pentru acele cookie-uri care sunt utile, dar nu strict necesare, vom cere mereu
consimțământul dvs. înainte de a le plasa.`}
          </div>

          <div className="mt-2">
            {`Pe lângă cookie-urile pe care le folosim, lucrând cu terțe părți pentru a vă oferi o experiență
oportună, acestea plasează diverse cookie-urile în calculatorul dvs., însă doar cu
consimțământul dvs. și veți fi notificat prealabil întotdeauna despre aceasta.`}
          </div>

          <div className="mt-2">
            {`Pentru mai multe informații despre utilizarea cookie-urilor, consultați`}
            <a href="/politica-de-cookies" className="ml-1 link-1">
              {`politica noastră privind cookie-urile.`}
            </a>
          </div>

          <div className="mt-4">
            <b>{`2.3. Când trimiteți o solicitare prin intermediul site-ului nostru`}</b>
          </div>
          <div className="mt-2">
            {`Utilizăm aceste informații pentru a răspunde cererii dvs. Putem să vă trimitem un e-mail de mai
multe ori după ce ați făcut o solicitare, pentru a ne asigura că am răspuns la întrebarea dvs. și
pentru a îmbunătăți experiența utilizatorilor noștri.`}
          </div>
          <div className="mt-2">
            {`Solicitarea dvs. este stocată și procesată timp de 3 ani din rațiuni de siguranță după care va fi
ștearsă împreună cu numele și adresa de e-mail sau telefon conform informațiilor de mai jos.
Datele personale colectate nu se mai divulgă altor persoane.`}
          </div>

          <div className="mt-2">
            {`Nu folosim informațiile furnizate de dvs. prin formularul de contact pentru a lua decizii
automate care ar putea să vă afecteze.`}
          </div>

          <div className="mt-4">
            <b>{`2.4. Abonarea la newsletter-ul nostru`}</b>
          </div>
          <div className="mt-2">
            {`Când trimiteți o solicitare de abonare la newsletter-ul nostru vă solicităm adresa de e-mail.`}
          </div>
          <div className="mt-2">
            {`De asemenea, veți bifa și un Acord de comunicare comercială prin care vi se vor arăta pe scurt
categoriile de date prelucrate, durata stocării și drepturile dumneavoastră.`}
          </div>

          <div className="mt-2">
            {`Utilizăm aceste informații pentru a vă transmite ultimele știri, proiecte și alte informații de
interes.`}
          </div>

          <div className="mt-2">
            {`Prin intermediul abonării la newsletter, datele sunt stocate și procesate până la retragerea
consimțământului dumneavoastră. Puteți să vă retrageți consimțământul oricând printr-o
solicitare transmisă prin adresa de e-mail sau prin efectuarea unui click pe casuța dezabonare
care se va afla la finalul fiecărui newsletter pe care ți-l trimitem. Datele personale colectate nu
se mai divulgă altor persoane.`}
          </div>

          <div className="mt-4 ml-3">
            <b>{`3. Drepturile dvs. ca persoană vizată`}</b>
          </div>

          <div className="mt-2">
            {`Ne puteți întreba`}
            <b>{` ce informații avem despre dumneavoastră `}</b>
            {`și ne puteți cere`}
            <b>{` să le corectăm `}</b>
            {`dacă sunt inexacte. Dacă v-am cerut consimțământul dvs. pentru a procesa datele personale,`}
            <b>{` puteți retrage acest consimțământ în orice moment. `}</b>
          </div>

          <div className="mt-2">
            {`Dacă prelucrăm datele dvs. personale pentru a îndeplini un contract sau în temeiul
consimțământului, `}
            <b>{` puteți să ne cereți să vă oferim o copie a informațiilor într-un format
care să poată fi citit, astfel încât să îl puteți transfera altui furnizor.`}</b>
          </div>

          <div className="mt-2">
            {`Dacă prelucrăm datele dvs. personale pe bază de consimțământ sau de interes legitim, `}
            <b>{` puteți solicita ștergerea `}</b>
            {`datelor dvs.`}
          </div>

          <div className="mt-2">
            {`Aveți `}
            <b>{` dreptul să ne cereți să nu mai folosim informațiile dvs. pentru o perioadă de timp `}</b>
            {`dacă credeți că nu facem acest lucru în mod legal.`}
          </div>

          <div className="mt-2">
            {`Toate drepturile indicate se pot concluziona astfel: dreptul de acces la datele personale,
rectificarea sau ştergerea acestora, restricţionarea prelucrării sau a dreptului de a se opune
prelucrării, precum şi dreptul la portabilitatea datelor. Portabilitatea datelor presupune posibilitatea dumneavoastră de a ne solicita să transferăm toate datele pe care le deținem unei
alte persoane indicate expres de către dumneavoastră.`}
          </div>

          <div className="mt-2">
            {`Pentru a trimite o solicitare privind datele dvs. personale prin e-mail, poștă sau telefon, utilizați
informațiile de contact furnizate mai sus în secțiunea `}
            <b>{` Cine suntem noi `}</b>
            {`din această politică. Vom
răspunde solicitărilor dumneavoastră în maxim 30 de zile de la data solicitării.`}
          </div>

          <div className="mt-4 ml-3">
            <b>{`4. Dreptul de a depune plângere`}</b>
          </div>

          <div className="mt-2">
            {`Dacă aveți o plângere cu privire la utilizarea de către dvs. a informațiilor dvs., am prefera să ne
contactați prima oară pe noi, pentru a putea remedia în mod amiabil cererea. Cu toate acestea,
puteți, de asemenea, să contactați Autoritatea Naţională de Supraveghere a Prelucrării Datelor
cu Caracter Personal pentru Informații prin intermediul site-ului lor la `}
            <a className="link-1" href="http://www.dataprotection.ro/" target="_blank" rel="noopener noreferrer">
              http://www.dataprotection.ro/{" "}
            </a>
            {` sau să le scrieți la adresa:`}
          </div>
          <div className="mt-2">
            {`B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336, București, Romania`}
          </div>

          <div className="mt-4 ml-3">
            <b>{`5. Transferul datelor cu caracter personal către o țară terță`}</b>
            
          </div>
          <div className="mt-2">
          {`Nu avem intenţia de a transfera date cu caracter personal către o ţară terţă sau din afara UE sau
o organizaţie internaţională. Dacă survine acest lucru, veți fi notificat în acest sens pentru a vă
da consimțământul sau pentru a vă indica garanțiile adecvate care asigură același standard de
protecție a prelucrării datelor cu caracter personal.`}
          </div>

          <div className="mt-4 ml-3">
            <b>{`6. Divulgarea datelor personale`}</b>
            
          </div>
          <div className="mt-2">
          {`Datele personale nu vor fi divulgate persoanelor terțe cu excepția situațiilor în care divulgarea
este necesară pentru a putea oferi serviciile noastre. În mod ocazional semnăm contracte cu alte
companii și parteneri de afaceri care lucrează în numele nostru, cum ar fi companii tehnice
pentru procesarea și livrarea sistemelor și tehnologiilor care dezvoltă produsele și serviciile
noastre și în aceste cazuri vom divulga informațiile necesare. Furnizorilor de servicii li se va
permite să obțină numai datele personale necesare pentru a-și livra serviciile. Nu vom divulga
date personale terților cu scopul de a le permite să își comercializeze produsele și serviciile
către clienții noștri. Dacă utilizatorii nu doresc ca noi să divulgăm date personale acestor
companii, aceștia sunt rugați să ne contacteze la adresa de e-mail indicată mai sus.`}
          </div>
          <div className="mt-2">
          {`În anumite situații putem fi nevoiți să dezvăluim date personale ca și răspuns la cererile
autorităților de supraveghere pentru a îndeplini cerințe legale. De asemenea vom divulga
informații personale daca este cerut de lege, de exemplu pentru a ne conforma cu o citație sau
alte procese legale, atunci când considerăm de bună credință că divulgarea datelor este
necesară pentru a ne proteja drepturile, siguranța clienților sau siguranța celorlalți, investigarea
fraudelor sau ca răspuns la o cerere a autorităților publice.`}
          </div>


          <div className="mt-4 ml-3">
            <b>{`7. Securitatea procesării`}</b>
            
          </div>
          <div className="mt-2">
          {`Vom procesa datele în mod sigur, vom aplica și menține măsuri tehnice adecvate pentru a
proteja datele personale împotriva distrugerii sau pierderii accidentale sau ilegale, alterare,
divulgare sau acces neautorizat, în mod special atunci când procesarea presupune transmiterea
datelor printr-o rețea atât cât și împotriva oricărei alte forme de procesare ilegală. Întrebări
legate de securitatea datelor personale pot fi trimise către adresa de e-mail indicată mai sus.`}
          </div>
          <div className="mt-2">
          {`În concret, datele de pe acest site sunt colectate securizat prin HTTPS over TLS v1.3, stocate
Mailchimp, Google Drive, Dropbox precum și procesate manual din mediul de stocare.`}
          </div>
          <div className="mt-2">
          {`Eventuale e-mailuri de marketing pot fi trimise doar cu consimțământul dumneavoastră expres.
Această formă specifică de consimțământ trebuie dată în mod liber, informată specific și precis.
Aceste cereri sunt îndeplinite atunci când ați optat să primiți e-mailuri de marketing (ați
aprobat activ).`}
          </div>
          <div className="mt-2">
          {`Veți avea mereu dreptul de a obiecta, la cerere și fără costuri, la procesarea datelor cu caracter
personal în scopuri de marketing direct fără a trebui să furnizeze justificări concrete.`}
          </div>
          <div className="mt-2">
          {`E-mailurile de marketing conțin informații pe care le considerăm interesante pentru
dumneavoastră, atât cât și știri de ultimă oră legate de produsele și serviciile noastre.`}
          </div>


          <div className="mt-4 ml-3">
            <b>{`8. Actualizări la această politică de confidențialitate`}</b>
            
          </div>
          <div className="mt-2">
          {`Revizuim regulat și, dacă este cazul, actualizăm periodic această politică de confidențialitate
atunci când intervin modificări în urma prestării de servicii. Dacă vrem să folosim datele dvs.
personale într-un mod pe care nu l-am identificat anterior, vă vom contacta pentru a vă oferi
informații despre acest lucru și, dacă este necesar, pentru a vă solicita acordul.`}
          </div>
          <div className="mt-2">
          {`Vom actualiza numărul versiunii și data acestui document de fiecare dată când acesta este
modificat.`}
          </div>

          <div className="mt-2">
            <table className="w-100">
              <tr>
                <td>
                  <b>Politică aprobată în data de:</b>
                </td>
                <td>27/09/2023</td>
              </tr>
            </table>
          </div>

          <div className="mt-2">
          {`Politica și practicile noastre de protejare a datelor se focusează pe procesarea, schimbul și
stocarea corespunzătoare și legală a informațiilor cu caracter personal și totodată pe asigurarea
confidențialității, integrității și disponibilității.`}
          </div>
          
          <div className="mt-2">
          {`Depunem regulat diligențe ca website-ul nostru să fie în conformitate cu Regulamentul General
de Protecție a Datelor (“GDPR'), (Regulament (EU) 2016/679).`}
          </div>
          
          <div className="mt-2">
          {`Vă mulțumim pentru interesul acordat fata de website-ul nostru.`}
          </div>
          
          <div className="mt-2">
          {`Pentru orice întrebări suplimentare cu privire la modul în care datele cu caracter personal sunt
prelucrate, vă rugăm să ne scrieți la adresa de e-mail de mai sus.`}
          </div>



























        </div>
      </div>

      <div className="p-relative mt-7">
        <Footer />
      </div>
    </div>
  );
}

export default PoliticaDeConfidentialitate;
