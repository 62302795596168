import React, { useEffect } from "react";
import "./spline-supreme.scss";
import ButtonHero from "../button-hero/button-hero";
import ProgressBar from "../progress-bar/progress-bar";
import videoSource from "./../../assets/gem-video/glowing-gem.webm";
import singleGemImage from "./../../assets/gem-video/single-gem.png";
import movVideoSource from "./../../assets/gem-video/glowing-gem.mov";
import { isAppleDevice } from "../../utils/utils";
function SplineSupreme({ offer, activePriceTimeline, onClick = () => {} }) {
  return (
    <div className="supreme-wrapper">
      <div className={`${isAppleDevice()?"is-apple-device":""}`}>
        {isAppleDevice() ? (
          <div className="supreme-3d-object">
            <img src={singleGemImage} alt="Diamond" height="100%" />
          </div>
        ) : (
          <video height="100%" autoplay loop muted className="supreme-3d-object autoplay-on-scroll">
            <source src={movVideoSource} type='video/mp4; codecs="hvc1"' />
            <source src={videoSource} type="video/webm" />
          </video>
        )}
      </div>

      <div className="supreme-package-offer">
        <div className={`supreme-package-content`}>
          <div className={`h2 package-title text-left w-100 pl-2 pr-2`}>{offer.title}</div>
          <div className="d-flex gap-2 mt-3 w-100 bullet-points-wrapper">
            <div className="bullet-points-column">
              {offer.points.slice(0, offer.points?.length / 2).map((point, index) => {
                return (
                  <div className="" key={"offer.points-" + index}>
                    <div
                      // className={`${
                      //   index === 0 ? "hero-text-underline underline-variant-4 pt-2 mb-2" : "bullet-point-left mt-1"
                      // }`}>
                      className="bullet-point-left mt-1">
                      {point}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="bullet-points-column">
              {offer.points.slice(offer.points?.length / 2, offer.points?.length).map((point, index) => {
                return (
                  <div className="mt-1" key={"offer.points-" + index}>
                    <div className="bullet-point-left">{point}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="mt-2 pl-0 pl-md-2 pr-md-4">
            <ProgressBar percentage={offer.soldOutPercentage}></ProgressBar>
          </div>

          <div className="d-flex gap-2 w-100 d-flex justify-content-around mt-3 mb-3 flex-wrap">
            <div className="">
              <div className="d-flex flex-direction-column">
                <div className="">
                  {offer.prices.map((priceType, index) => {
                    return (
                      <div
                        className={`mt-1 d-flex align-items-center p1 font-black
                ${activePriceTimeline === index ? "" : "transparent-text"}`}
                        key={"offer.prices-" + index}>
                        <div className="mr-2 d-flex align-items-center gap-1">
                          <div className={`${index === 1 && activePriceTimeline !== index ? "blurred-text" : ""}`}>
                            {priceType.salePrice || priceType.price}
                          </div>
                          <div>{offer.currency}</div>
                        </div>

                        {/* <div className="mr-2">{`${priceType.price} ${offer.currency}`}</div> */}
                        <div>{priceType.type}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="">
              <div className="gap-2 text-center">
                {offer.isOnSale ? (
                  <>
                    <div>
                      <div className="">
                        <div className="h3 h2-md price transparent-text strikeThrough">{`${offer.prices[activePriceTimeline].price} ${offer.currency}`}</div>
                        {offer.prices[activePriceTimeline]?.discount ? (
                          <>
                            <div className="p1 mt-1 text-center">{`Reducere: ${offer.prices[activePriceTimeline].discount}`}</div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    {/* <div className="h3 h2-md price transparent-text strikeThrough">
                      <div className="">{`${offer.prices[activePriceTimeline].price} ${offer.currency}`}</div>
                    </div> */}
                    <div className="h3 h2-md price mt-2 font-black text-underline text-underline-black underline-variant-9">
                      <div className="">{`${offer.prices[activePriceTimeline].salePrice} ${offer.currency}`}</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="h3 h2-md price font-black text-underline text-underline-black underline-variant-9">
                      <div className="">{`${offer.prices[activePriceTimeline].price} ${offer.currency}`}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <ButtonHero
              onClick={onClick}
              width="400px"
              color={`${offer.isSupreme ? "white-black" : "red-black"}`}
              is3d={true}
              isPulsing={true}
              content="Rezerv acum"
              scale="0.8"></ButtonHero>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SplineSupreme;
